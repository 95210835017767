
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.fieldContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid $hive-primary-blue;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-size: 16px;
  color: $hive-grey-black;
  font-weight: 600;
}

.value {
  font-size: 16px;
  color: $hive-gray;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.buttonName {
  color: $hive-primary-blue;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.buttonSave {
  width: 10%;
}

.inputField {
  margin-top: -5px;
  padding: 11px 0px 11px 0px;
}
