
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.removeIcon {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-bottom: 4px;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -25px;
  :global(.ant-btn-circle) {
    border: none;
  }
}

.subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-gray;
}

.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
