
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px 0;
}

.closeImage {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.closeImage:dir(rtl) {
  left: 30px;
  right: auto;
}

.title {
  @extend .h_3;
  color: $hive-grey-black;
}
