
          @import "src/scss/styles.scss";
        
.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 560px;
  min-height: 380px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  padding-top: 20px;
  margin-top: auto;
  margin-left: auto;
  width: 150px;
  justify-content: space-between;
}

.exit {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 30px;
  top: 30px;
  path {
    fill: $hive-gray;
  }
  cursor: pointer;
}

.exit:dir(rtl) {
  left: 30px;
  right: auto;
}
