
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  @extend .h_2;
  letter-spacing: -2.5px;
  margin: 29px 0 16px 10px;
}

.subtitle {
  @extend .h_6;
  color: $hive-gray;
  margin-left: 10px;
}

.searchWrapper {
  height: 40px;
  width: 310px;
  align-self: flex-end;
}

.search {
  border-radius: 100px;
  padding: 8px 12px 8px 47px;
  background-color: rgba($hive-white, 0.55);
  border: 2px solid transparent;
  transition: 0.3s ease-in-out;

  &:focus-visible {
    outline: none;
    border-color: $hive-primary-blue;
  }
}

.searchRtl {
  padding-right: 47px;
  padding-left: 12px;
}
