
          @import "src/scss/styles.scss";
        
.sectionWrapper {
  background-color: $hive-new-orange;
  background-image: url("../../../images/hero-left.png");
  background-size: 40%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.textWrapper {
  padding: 200px 80px;
  z-index: 999;
}

.welcomeText {
  color: #1e1e1e;
  font-weight: 700px;
  font-size: 36px;
}

.primaryText {
  color: #ffffff;
  font-weight: 700px;
  font-size: 36px;
  width: 680px;
  margin-top: 20px;
  line-height: 1.2;
  z-index: 10;
}

.secondaryText {
  color: #1e1e1e;
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  margin-top: -20px;
}

.playBtn {
  background-color: #01b3c5;
  color: #ffffff;
  font-size: 24px;
  line-height: 1.5;
  width: 320px;
  height: 70px;
  gap: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.heroBee {
  position: absolute;
  right: 80px;
  bottom: 80px;
  width: 20%;
  max-width: 300px;
}

.rightBg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35%;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  position: absolute;
  right: 40px;
  top: 40px;
}

.logo {
  width: 120px;
  height: 100%;
}

.modal {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  height: 600px;
}

.loadingWrapper {
  position: absolute;
}
