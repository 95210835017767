
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.outer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  background: rgba($hive-white, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  &:hover {
    box-shadow:0px 4px 12px rgba($hive-black, 0.2);
  }

  .img {
    padding: 0 5px;
  }

  .news {
    display: flex;
    @extend .h_6;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.articleImage {
  height: 120px;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  align-items: flex-start;
  background-position: top center;
  cursor: pointer;
}

.bottomText {
  display: flex;
  align-items: center;
  margin-top: auto;
  @extend .h_8;
  text-align: left;
  color: $hive-grey-black;
  white-space: nowrap;
}

.organisation {
  color: $hive-primary-blue;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.more {
  position: absolute;
  left: 85%;
  top: 3%;
}

.dot {
  font-weight: bold;
  margin: 0px 5px;
}

.cardBottom {
  background-color: rgba($hive-white, 0.4);
  height: 130px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 16px 24px;
}

.megaphone {
  position: absolute;
  left: 2%;
  top: 3%;
  width: 25px;
  height: 30px;
}

.trending {
  position: absolute;
  left: 13%;
  top: 3%;
  width: 70px;
  height: 30px;
}
