
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $hive-grey-black;
}

.copyLinkWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $hive-white;
  border-radius: 25px;
  padding: 2px 10px;
}

.copyLink {
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonCopy {
  padding-left: 8px;
  :global(.ant-btn-round.ant-btn-sm) {
    height: 28px;
  }
}

.pointer {
  cursor: pointer;
}

.copyImg {
  @extend .pointer;
  margin-right: 10px;
}

.copyImg:dir(rtl) {
  margin-right: 0px;
  margin-left: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.textArea {
  padding: 16px 20px;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  max-height: 20vh;
  overflow-y: auto;
}

.tabItemRow {
  display: flex;
  flex-direction: row;
  padding: 8px 2px;
  align-items: center;
  justify-content: space-between;
}

.tabItemTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $hive-grey-black;
}

.checkbox {
  width: 100%;
  label {
    width: 100%;
    padding: 0;
  }
  p {
    margin: 0;
  }
  span {
    // Important needed to update default checkbox position
    left: initial !important;
    right: 5px;
  }
}

.checkbox:dir(rtl) {
  span {
    left: 5px !important;
    right: auto;
  }
}

.buttonBorderless {
  :global(.ant-btn-circle) {
    border: none;
  }
}

.shareButton {
  :global(.ant-btn-round.ant-btn-lg) {
    height: 48px;
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: $hive-white-silver;
  margin: 20px 0 10px 0;
}

.modal {
  overflow: auto;
}

.tabs {
  :global(.ant-tabs) {
    overflow: initial;
  }
}
