
          @import "src/scss/styles.scss";
        
.container {
  padding: 100px 80px;
  position: relative;
}

.sectionsWrapper {
  display: flex;
  justify-content: space-between;
}

.hexagonsContainer {
  position: relative;
  width: 700px;
  height: 700px;
  flex-shrink: 0;
}

.hexagonDark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 380px;
  height: 329px;
  bottom: 0;
  background-image: url("../../../images/hexagon-dark.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hexagonLight {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 380px;
  height: 329px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("../../../images/hexagon-light.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hexagonOrange {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 380px;
  height: 329px;

  background-image: url("../../../images/hexagon-orange.png");
  background-size: cover;
}

.number {
  cursor: pointer;
  color: #ffffff;
  font-size: 110px;
  font-weight: 700;
}

.textContainer {
  font-size: 32px;
  padding-top: 60px;
  font-weight: 700;
  width: 500px;
  color: #164057;
  margin-left: 30px;
}

.hivePulseBg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35%;
  max-width: 450px;
}
