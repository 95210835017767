
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.textareaWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
}

.textarea {
  border: none;
  border-radius: 10px;
  min-height: 100px;
  padding: 5px 10px;
  font-family: "Arial";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  resize: none;

  &.border {
    border: $input-border;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  &:focus {
    border: $input-border-focus;
  }
}

.textareaRtl {
  text-align: right;
}

.label {
  @extend .globalLabel;
}

.boldLabel {
  @extend .label;
  @extend .globalBoldLabel;
}
