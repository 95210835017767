
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.pendingInvitationsCard {
  display: flex;
  align-items: center;
  padding: 10px 40px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 12px;
  background-color: rgba($hive-white, 0.6);
  gap: 15px;
}

.pendingInvitationsText {
  .pendingInvitationsLink {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #567ae8;
    cursor: pointer;
  }
  .pendingInvitationsLinkResource {
    color: #567ae8;
    cursor: pointer;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.buttonDecline {
  background-color: rgba($hive-white, 0.6);
  margin-left: 5px;
  border: 1px solid $hive-primary-blue;
  border-radius: 100px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  color: $hive-primary-blue;
  height: 40px;
}

.buttonAccept {
  background-color: $hive-primary-blue !important;
  margin-left: 5px;
  color: $hive-white !important;
  border: 1px solid $hive-primary-blue;
  border-radius: 100px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  height: 40px;
}

.buttonAccept:dir(rtl) {
  > span > img {
    margin-right: 4px;
    transform: translateY(-1px);
  }
}

.buttonDecline:dir(rtl) {
  > span > img {
    margin-right: 4px;
    transform: translateY(-1px);
  }
}

.iconBeforeTitle {
  margin-right: 10px;
}

.iconBeforeTitle:dir(rtl) {
  margin-left: 10px;
}
