
          @import "src/scss/styles.scss";
        
.breadCrumbsContainer {
  :global(.ant-breadcrumb-separator) {
    color: $hive-grey-black;
  }
}

.breadCrumbStart {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-grey-black;
  cursor: pointer;
}

.item {
  color: $hive-grey-black;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  cursor: pointer;
}

.lastItem {
  color: $hive-gray;
  cursor: default;
}

.separatorIcon {
  font-size: 12px !important;
}

.separatorIcon:dir(rtl) {
  transform: rotate(180deg);
}
