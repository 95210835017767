
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  margin-top: 20px;
  font-size: 16px;
  color: $hive-grey-black;
  font-weight: 600;
}

.inputLabel {
  font-weight: normal;
  font-size: 14px;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-left: 5px;
}
