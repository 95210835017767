
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles";
.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2.5px;
  text-align: left;
  margin-bottom: 38px;
}

.feedbackContainer {
  min-height: 447px;
  display: flex;
  background-color: rgba($hive-white, 0.6);
  padding: 32px;
  flex-direction: column;
}

.reportTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-bottom: 32px;
}

.reportSubtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
}

.reportSubtitle:dir(rtl) {
  text-align: right;
}

.reportText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 24px;
}

.reportText:dir(rtl) {
  text-align: right;
}

.headRow {
  display: flex;
  height: 56px;
  background-color: rgba($hive-white, 0.6);
  border-bottom: 1px solid $hive-white-silver;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.headRowText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: center;
  color: $hive-grey-black;
  margin-right: 55px;
  padding-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
.idText {
  @extend .headRowText;
  padding-left: 33px;
}
.avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: top center;
}
.userInfoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
