
          @import "src/scss/styles.scss";
        
// ----------------------------------
// PREVIEW IMAGE
// ----------------------------------

.removeIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.removeIcon:dir(rtl) {
  left: 20px;
  right: auto;
}

.mainWrapper {
  margin: 0 auto;
}

.image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}

.buttonsWrapper {
  display: flex;
  margin-top: 51px;
  justify-content: center;
}

.button {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background-color: $hive-light-gray;
  }
}

.inputFile {
  display: none;
}

.buttonText {
  @extend .h_6;
  color: $hive-gray;
}

.icon {
  max-height: 22px;
  margin-bottom: 5px;
}

// ----------------------------------
// UPLOAD IMAGE
// ----------------------------------

.uploadImageWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.uploadHeaderText {
  margin: 0 auto;
  margin-bottom: 30px;
}

.uploadButton {
  margin: 0 auto;
  margin-top: 40px;
}

.closeIcon {
  cursor: pointer;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.closeIcon:dir(rtl) {
  left: 20px;
  right: auto;
}
