
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.reactionButtonStyle {
  margin: 0px;
  padding: 0px;
}

.reactionTextStyle {
  margin: 0px;
  text-align: center;
  align-items: flex-start;
  font-size: 14px;
  color: $hive-disabled-color;
}

.reactionTextStyleLiked {
  @extend .reactionTextStyle;
  color: $hive-select-blue;
}
