
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.modalWrapper {
  width: 30%;

  @include breakpoint(xl) {
    width: 40%;
  }

  @include breakpoint(lg) {
    width: 40%;
  }

  @include breakpoint(md) {
    width: 60%;
  }

  @include breakpoint(sm) {
    width: 80%;
  }
}

.removeIcon {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-bottom: 4px;
}

.closeButtonWrapper {
  :global(.ant-btn-circle) {
    border: none;
  }
}

.errorUserMessage {
  color: $hive-danger-red;
}

.errorCommunitiesMessage {
  position: relative;
  top: 5px;
  color: $hive-danger-red;
}

.inviteUserContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.formGroup {
  margin: 10px 0;
}

.formColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.inputWrapper {
  width: 100%;
  height: auto;
}
