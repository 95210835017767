
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.modalContainer {
  position: relative;
  background-color: $hive-category-background-color;
  width: 50%;
  right: auto;
  bottom: auto;
  padding: 40px 40px 32px 40px;
  border-radius: 12px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 15px $hive-gray;
}

.default {
  &:global(.ReactModal__Content) {
    background-color: $hive-white;
  }
}

.primary {
  &:global(.ReactModal__Content) {
    background-color: $hive-category-background-color;
  }
}

.narrow {
  &:global(.ReactModal__Content) {
    max-width: 560px;
    background-color: $hive-white;
  }
}

:global(.ReactModal__Overlay) {
  position: fixed;
  max-width: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // Overlay z-index is overwritten by library without !important. We need higher z-index because of poopover having 1013 z-index.
  z-index: 1103 !important;
  // Important is required to overwrite the default background of the overlay.
  background: rgba($hive-backdrop-background, 0.5) !important;
}
