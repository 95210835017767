
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.collectionHeaderContainer {
  padding: 15px 0;
  min-height: 90px;
}

.rightSideRowContainer {
  gap: 14px;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 40%;
  gap: 12px;
}

.rightSide {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex: 1 1 60%;
}

.sortSelect {
  width: 150px;
}
