
          @import "src/scss/styles.scss";
        
.description {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  word-wrap: break-word;
}

.trimmed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  max-height: 100px;
  -webkit-box-orient: vertical;
}

.shadow {
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.seeMore {
  color: $hive-primary-blue;
  cursor: pointer;
}
