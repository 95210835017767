
          @import "src/scss/styles.scss";
        
@import "../../../../scss/styles.scss";

.cardWrapper {
  display: flex;
  margin-bottom: 12px;
  height: 84px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}

.leftWrapper {
  width: 83px;
  padding: 17px 18px;
  background-size: cover;
  background-position: top center;
  border-radius: 8px 0 0 8px;
  .smallDate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 14px;
    font-size: 10px;
    line-height: 12px;
    background-color: $hive-primary-blue;
    color: $hive-white;
    border-radius: 3px 3px 0px 0px;
  }
  .bigDate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 34px;
    font-size: 21px;
    line-height: 30px;
    background-color: $hive-white;
    color: $hive-grey-black;
    border-radius: 0px 0px 3px 3px;
  }
}

.leftWrapper:dir(rtl) {
  border-radius: 0 8px 8px 0;
}

.rightWrapper {
  width: calc(100% - 83px);
  padding: 16px;
  background-color: $hive-white;
  .name {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $hive-grey-black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .additionalInfo {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $hive-gray;
    .hostedBy {
      padding-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span {
        cursor: pointer;
        color: $hive-primary-blue;
      }
    }
  }
}

.createEvent {
  cursor: pointer;
  color: $hive-primary-blue;
}
