
          @import "src/scss/styles.scss";
        
.outer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.innerOthers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.innerCollectionContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.loadMoreButton {
  align-self: center;
  padding: 80px 0 10px 0;
}

.plusIcon {
  margin-right: 10px;
}

@media (max-width: 1300px) {
  .inner {
    grid-template-columns: repeat(3, 1fr);
  }
}

.newCollection {
  color: $hive-primary-blue;
  margin: 0 4px 0 0;
  cursor: pointer;
}

.sectionTitle {
  @extend .h_3;
  letter-spacing: -1.5px;
  text-align: left;
  margin: 20px 0;
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
