
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.noPendingInvitationsContainerOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  .noPendingInvitationsTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -1.5px;
    margin-top: 20px;
  }
  .noPendingInvitationsSubtitle {
    max-width: 450px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #697377;
  }
  .noPendingInvitationsImage {
    height: 70px;
    width: 70px;
  }
}
