
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.pageWrapper {
  overflow-y: scroll;
}

.accordionHeader {
  font-weight: bold;
  font-size: 18px;
  :global(.ant-collapse-header) {
    padding: 4px 0 !important;
  }
  :global(.ant-collapse-content-box) {
    padding: 6px 0 !important;
  }
  :global(.ant-collapse-expand-icon) {
    position: relative;
    top: -2px;
  }
  :global(.ant-collapse-header-text) {
    font-size: 20px;
  }
}

.accordionHeader:dir(rtl) {
  :global(.ant-collapse-expand-icon) {
    position: relative;
    top: 5px;
    transform: rotate(180deg);
    left: -5px;
  }

  :global(.ant-collapse-item-active > div > .ant-collapse-expand-icon) {
    transform: rotate(0deg);
    top: 0px;
    left: 8px;
  }
}

:global(.ant-collapse-expand-icon:dir(rtl)) {
  left: 4px;
  top: 0px;
}

.regularText {
  font-size: 14px;
  font-weight: normal;
}

.backButton {
  margin-top: -6px;
  margin-bottom: -6px;
}

.communityName {
  margin-bottom: 0;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.chat {
  width: 100%;
}

.bannerSection {
  height: 350px;
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.mainWrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px 25px 65px;
  gap: 21px;
}

.leftWrapper {
  width: calc(100% - 330px);
}

.rightWrapper {
  width: 330px;
  flex-shrink: 0;
}

.infoContainer {
  padding-top: 14px;
  color: $hive-dark-grey;
}

.discussionsContainer {
  padding-top: 60px;
}

.goalsTitle {
  font-weight: bold;
  margin-top: 50px;
}

.titleContainer {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  button {
    position: static;
    margin-left: 12px;
    margin-top: 12px;
    flex-shrink: 0;
  }
}

.communityImage {
  background-position: center;
  background-size: cover;
  height: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 100%;
}

.communityIconContainer {
  background-color: $hive-disabled-background;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  width: 32px;
}

.discussionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recentDiscussions {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.hashtag {
  font-size: 18px;
  font-weight: 500;
  font-family: cursive;
  cursor: pointer;
}

.interest {
  color: $hive-gray;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.separator {
  padding: 0 20px;
  margin-top: 28px;
  margin-bottom: 40px;

  hr {
    border-top: 1px solid $hive-neutral;
  }
}

.collectionsSpaceContainer {
  .communitySpaceTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .communitySpaceTitle {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $hive-grey-black;
    }

    * {
      margin: 0;
      padding: 0;
    }
  }

  .collectionsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    gap: 16px;
  }
}

.roleTag {
  background-color: $hive-primary-blue;
  color: $hive-white;
  height: 32px;
  font-size: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  flex-shrink: 0;
  font-weight: 400;
}

.joinGroupButton {
  padding: 0px;
  button {
    margin-top: 6px;
  }
}

.manageButton {
  display: flex;
  align-items: center;
  justify-content: left;
  color: $hive-primary-blue;
  max-height: 18px;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-feature-settings: "tnum" on, "lnum" on;
  gap: 8px;
}

.menuWrapper {
  position: relative;
  left: 120px;
}

.boldButtonText > button > span {
  font-weight: 700;
}
