
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.container {
  background-color: rgba($hive-white, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  border-radius: 6px;
  border: 1px solid $hive-white;
  padding: 48px 48px 38px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: $hive-grey-black;
}

.content {
  font-size: 16px;
  font-weight: 400;
  color: $hive-gray;
  margin-top: 16px;
  margin-bottom: 22px;
}

.buttonContainer {
  border: 0.5px solid $hive-primary-blue;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.buttonText {
  color: $hive-primary-blue;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.image {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  margin-bottom: 3px;
}
