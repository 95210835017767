
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.container {
  background-color: $hive-attachment-background;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  width: auto;
  padding: 10px;
  position: relative;
  border: 1px solid $hive-primary-blue;
}

.fileContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 10px;
}

.fileName {
  font-weight: 400;
  font-size: 12px;
}

.size {
  color: $hive-grey-name;
  font-weight: 400;
  font-size: 10px;
}

.removeIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  bottom: 40px;
  width: 12px;
  height: 12px;
}

.universalFileIcon {
  color: $hive-pure-red;
  svg {
    width: 30px;
    height: 30px;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
}

.downloadImage {
  margin-bottom: 5px;
}

.fileName {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-grey-black;
}

.fileSize {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-grey-name;
}
