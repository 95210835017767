
          @import "src/scss/styles.scss";
        
.outer {
  padding: 0 0 50px 0;
}

.innerText {
  padding: 30px 0 0 0;
}

.title {
  @extend .h_3;
  margin: 0 0 18px 0px;
}

.subtitle {
  @extend .h_6;
  color: $hive-gray;
  font-weight: 400;
}

.recommendedContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filtersSection {
  display: flex;
  justify-content: space-between;
}

.activeButton {
  &:global(.ant-btn) {
    color: $hive-primary-blue;
    background-color: rgba($hive-selected-pill-blue, 0.3);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px;

    &:active {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }

    &:focus {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }
  }
}

.notActive {
  &:global(.ant-btn) {
    color: $hive-black;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px;
  }
}

.buttonsSection {
  display: flex;
  gap: 10px;
}

.categoriesButton {
  > span:dir(rtl) {
    direction: ltr;
  }
}

.categoriesButton {
  > span:dir(ltr) {
    direction: rtl;
  }
}

.selectWrapper {
  min-width: 170px;
}

.categoryIcon {
  height: 16px;
  margin-top: -4px;
  margin-right: 5px;
}

.categoryIcon:dir(rtl) {
  margin-right: 0;
  margin-left: 5px;
}

.noResultsContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgWrapper {
  margin-bottom: 30px;
}

.img {
  width: 150px;
}

.noResultsText {
  @extend .h_3;
  color: $hive-grey-black;
  margin-bottom: 20px;
}

.interestsLoaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
