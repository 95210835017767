
          @import "src/scss/styles.scss";
        
:global(.collections-tree-modal) {
  width: 601px;
  height: 611px;
  padding: 32px 40px;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TreeWrapper {
  flex-grow: 1;
  overflow: auto;

  span:global(.ant-tree-node-content-wrapper) {
    word-break: break-all;
  }

  span:global(.collections-tree-node-title) {
    display: inline-block;
    line-height: 18px;
    vertical-align: middle;
  }

  span:global(.collections-tree-node-title.link) {
    font-size: 14px;
  }

  span:global(.collections-tree-node-title.empty) {
    font-size: 12px;
    line-height: 17px;
    color: $hive-gray;
  }

  span:global(.ant-tree-node-content-wrapper) {
    display: flex;

    span:global(.ant-tree-title) {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;

      span:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
}

.LoadingWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Title {
  font-weight: 500;
  color: $hive-black;
  margin-bottom: 32px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: row-reverse;

  .Button {
    margin-left: 16px;
    padding: 11px 20px;
    height: unset;
    font-weight: 500;
    line-height: 18px;
  }
}

.tabsWrapper {
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      &::before {
        border-bottom: none;
      }

      :global(.ant-tabs-nav-operations) {
        display: none;
      }
    }

    :global(.ant-tabs-nav-list) {
      :global(.ant-tabs-tab) {
        padding: 8px 0;
      }

      :global(.ant-tabs-tab) :global(.ant-tabs-tab-btn) {
        color: $hive-grey-name;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }

      :global(.ant-tabs-tab.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
        color: $hive-grey-black;
      }

      :global(.ant-tabs-ink-bar) {
        background: $hive-primary-blue;
      }
    }
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.date {
  color: $hive-gray;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  margin-top: 3px;
  margin-right: 4px;
}
