
          @import "src/scss/styles.scss";
        
@import "~antd/dist/antd.css";
@import "./scss/styles.scss";

* {
  font-family: EudoxusSans-Regular !important;
  word-spacing: 0.15em !important;
}

html {
  height: auto;
  overflow-y: auto;
}
body {
  height: auto;
  overflow: auto !important;
}

#zmmtg-root {
  display: none;
}

.main-app .ant-carousel .slick-dots li.slick-active:before {
  content: url("./modules//LandingPage/icons//icon-active-blue-dot.svg");
}

*:not(input, textarea) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}