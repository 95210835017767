
          @import "src/scss/styles.scss";
        
@import "../../scss//styles.scss";

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: $hive-light-gray;
  overflow-y: auto;
  margin-top: 84px;
}

.pageTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.newCommunityForm {
  width: 100%;
  max-width: 583px;
  padding: 10px;
}

.selectWrapper {
  position: relative;
}

.communityImageWrapper,
.communityTypeWrapper {
  padding: 15px 0;
}

.communityTypeHeading,
.communityImageHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
}

.communityImageRadioWrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 100%;
}

.imageRadioGroup {
  width: fit-content;
  img {
    border-radius: 10px;
  }
}

.inlineInputButton {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
}

.member {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.userDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  .image {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: top center;
  }
}

.role {
  margin-left: 40px;
  color: $hive-gray;
  font-size: 13px;
}

.name {
  padding-left: 10px;
  font-weight: 350;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  width: 100%;
  padding: 5px 0;
}

.cancelLink {
  display: flex;
  margin: auto;
  color: $hive-primary-blue;
  cursor: pointer;
}

.descriptionContainer {
  border: 1px solid $hive-black;
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 4px 10px;
  border-radius: 100px;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  @extend .h_7;
  margin-left: 5px;
}

.collectionTypeWrapper {
  padding: 15px 0;
}

.collectionTypeHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
}

.collectionTypeHeading:dir(rtl) {
  text-align: right;
}

.curatedIcon {
  margin-left: 4px;
  height: 20px;
  margin-top: -4px;
}

.usersLoaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.usersContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 20px;
  border: 1px solid $hive-disabled-background;
  scrollbar-width: none; /* Firefox */
}

.usersContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: $hive-white;
  gap: 10px;
  padding: 6px 10px 6px 15px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    background-color: $hive-light-gray;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 10px;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.member {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.userDetails {
  display: flex;
  align-items: center;
  gap: 8px;
}

.role {
  color: $hive-gray;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.name {
  margin-bottom: 0px;
  padding-left: 0px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 12px;

  img {
    cursor: pointer;
  }
}

.addCollaboratorsLabel {
  font-size: 16px;
  font-weight: 700;
}
