
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.headerContainer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  // Styles below added due to Story #23381 (Hiding search bar for alpha release 1.2)
  background-color: $hive-light-gray;
  height: 64px;
  box-shadow: 0px 0px 3px 0px rgba($hive-black, 0.4);
}

.headerIcons {
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
  height: 100%;
}

.headerIcons:dir(rtl) {
  right: auto;
  left: 20px;
}

.iconStyle {
  cursor: pointer;
}

.item {
  position: relative;
  margin-right: 15px;
  cursor: pointer;
}

.alert {
  position: absolute;
  right: -10px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $hive-primary-blue;
}

.notificationNumber {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.5px;
  color: $hive-white;
}

.avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: top center;

  &:hover {
    box-shadow: 0 0 8px rgb(0 0 0 / 60%);
    transform: perspective(500px) translateZ(3px);
  }
}

.menuItemWrapper {
  flex-grow: 1;
  width: 100%;
}

.menuItem {
  white-space: nowrap;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  transition: ease-in 100ms;
  cursor: pointer;
  &:hover {
    background: $hive-primary-blue;
    color: $hive-white;
  }
}

.avatarMenuOverlay {
  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.communityNotificationPopUp {
  :global(.ant-popover-inner) {
    border-radius: 10px;
  }
}
