
          @import "src/scss/styles.scss";
        
.menuItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 40px;
  background-size: cover;
  background-position: top center;
}

.image {
  width: 25px;
  height: 25px;
}

.userName {
  overflow-wrap: anywhere;
  color: $hive-grey-name;
}

.activeUserName {
  overflow-wrap: anywhere;
  color: $hive-new-blue;
}
