
          @import "src/scss/styles.scss";
        
.notifications {
  max-height: 50vh;
  padding: 16px;
  overflow: auto;
  box-sizing: content-box;
  min-width: 535px;
}

.notification {
  &:not(:last-child) {
    border-bottom: 1px solid $hive-disabled-background;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 20px;
}

.titleWrapper:dir(rtl) {
  text-align: right;
}

.noNotification {
  .title {
    padding-bottom: 24px;
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    color: $hive-grey-black;
  }
}

.heading {
  margin: 0;
  margin-right: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.action {
  margin-top: 5px;
  margin-bottom: 0;
  color: $hive-primary-blue;
  cursor: pointer;
}

.viewText {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: $hive-primary-blue;
  cursor: pointer;
}
