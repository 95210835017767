
          @import "src/scss/styles.scss";
        
.container {
  overflow-y: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.tabContainer {
  padding: 32px 32px 40px 32px;
}

.breadCrumbsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $hive-single-page-top-bar;
  padding: 16px 32px 22px 32px;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $hive-single-page-top-bar;
  padding: 0 32px;
}

.title {
  @extend .h_3;
  margin: 0 80px 0 0;
}

.actionWraper {
  display: flex;
  align-items: center;
}

.middleBar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $hive-single-page-top-bar;
  padding: 16px 32px;
}

.peopleOuter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  cursor: pointer;
  min-width: 200px;
  color: $hive-primary-blue;
}

.sortContainer {
  padding: 16px 32px 20px 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collectionTypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.sortDropdownWrap {
  min-width: 200px;
  margin: 0 5px 0 0;
}

.visitCommunityButton {
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  margin-right: 16px;

  &:focus,
  &:active {
    color: $hive-primary-blue !important;
    border-color: $hive-primary-blue !important;
    background-color: transparent !important;
  }

  svg {
    margin-right: 8px;
  }
}

.activeButton {
  &:global(.ant-btn) {
    color: $hive-primary-blue;
    background-color: rgba($hive-selected-pill-blue, 0.3);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px 7px;

    &:active {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }

    &:focus {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }
  }
}

.notActive {
  &:global(.ant-btn) {
    color: $hive-black;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px 7px;
  }
}

.gridContainer {
  padding: 0 24px 24px 24px;
}
