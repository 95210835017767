
          @import "src/scss/styles.scss";
        
.header {
  width: 100%;
  background-color: $hive-white;
  height: 70px;
  display: flex;
  align-items: center;

  a {
    margin-left: 15vw;
  }
}

@media (max-width: 960px) {
  a {
    margin-left: 20px;
  }
}

.logo {
  height: 45px;
  cursor: pointer;
}
