
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.titleContainer {
  font-size: 24px;
  font-weight: 650;
  padding-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10%;
}

.cancelLink {
  padding-right: 20px;
  cursor: pointer;
  font-size: 18px;
  color: $hive-primary-blue;
}

.removeIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
