
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 0;
  position: relative;
  .inputField,
  .inputWithIcon {
    border-radius: 100px;
    border: 2px solid $hive-light-gray;
    padding: 10px;
    margin-top: 10px;
    outline: none !important;
    outline-offset: none !important;
    transition: none !important;
    &:focus {
      outline: 2px solid $hive-primary-blue !important;
      border: 2px solid transparent;
    }
    &.removeFocus {
      outline-color: $hive-danger-red !important;
    }
  }
}

.inputLabel {
  @extend .h_6;
  color: $hive-black;
}

.inputErrorlabel {
  color: $hive-danger-red;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.search_country {
  border-radius: 100px;
  padding: 10px 10px;
  border: 2px solid $hive-light-gray;
  margin-top: 10px;
  background-color: rgba($hive-white, 0.55);
  background-image: url("../../images/icon.svg");
  background-repeat: no-repeat;
  position: left;
  padding-left: 30px;
  background-size: 20px;
  background-position-x: 8px;
  background-position-y: center;
}

.inputWithIcon {
  padding-left: 35px;
}

.inputIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.inputIcon:dir(rtl) {
  right: 10px;
  left: auto;
}

.wrapperFlexGrow {
  flex-grow: 1;
}

.marginRight {
  margin-right: 10px;
}

.interestsSearch {
  padding-left: 50px;
}

.numberInput {
  padding: 0;

  .inputField {
    margin-top: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
