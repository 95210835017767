
          @import "src/scss/styles.scss";
        
.chatPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.messenger {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
}

.input {
  position: relative;
  z-index: 2;
  width: 100%;
}

.attachmentBox {
  height: 80px;
  width: 95%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $hive-white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  margin-left: 10px;
  overflow-y: auto;
  border-bottom: 1px solid $hive-medium-gray;
  box-shadow: 0 30px 0px 0px $hive-white;
  padding: 10px;
}

.parentMessageBox {
  display: flex;
  width: 95%;
  padding: 12px 16px;
  margin-left: 10px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: $hive-white;
  box-shadow: 0 30px 0px 0px $hive-white;
  overflow-y: auto;
}
