
          @import "src/scss/styles.scss";
        
.container {
  background-color: $hive-grey-white;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  padding-top: 20px;
  width: 280px;
  height: 100%;
  overflow-y: auto;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.margins {
  margin-top: 10px;
}

.directChatImage {
  height: 20px;
  width: 20px;
  border-radius: 100%;
}

.wrapperButton {
  padding: 20px 0;
}
