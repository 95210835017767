
          @import "src/scss/styles.scss";
        
.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.5px;
    color: $hive-white;
  }

  &.primary {
    background-color: $hive-primary-blue;
  }

  &.secondary {
    background-color: $hive-new-blue;
  }
}
