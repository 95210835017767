
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.modalContainer {
  height: auto;
  width: 70%;
  min-height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.removeIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.orderHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.imageArrow {
  margin-left: 10px;
  margin-right: 10px;
}

.randomSearched {
  :global(.ant-btn-link) {
    padding: 0 !important;
    font-weight: 400 !important;
    color: $hive-gray !important;
  }
}

.mostSearched {
  :global(.ant-btn-link) {
    padding: 0 !important;
    font-weight: bold !important;
    text-decoration: underline !important;
    color: $hive-black !important;
  }
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  overflow-y: auto;
  max-height: 50vh;
}

.interest {
  cursor: pointer;
  border-radius: 30px;
  background-color: $hive-category-background-color;
  padding: 10px 20px;
  margin: 2px;
  border: none;
  font-weight: 400;
  border: 0.1px solid $hive-gray;
}

.searchHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.selectContainer {
  position: relative;

  .searchInterests {
    width: 400px;
  }
}

.searchImage {
  position: absolute;
  transform: translateY(-50%);
  top: 75%;
  left: 15px;
  z-index: 2;
  pointer-events: none;
}

.choosenInterest {
  cursor: pointer;
  border-radius: 30px;
  background-color: $hive-white-silver;
  padding: 10px 20px;
  margin: 2px;
  border: none;
  font-weight: 400;
  border: 0.1px solid $hive-gray;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}
