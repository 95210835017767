
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  height: 100%;
  background: rgba($hive-white, 0.6);
  width: 25%;
  padding: 64px 20px 0 15px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-size: cover;
  background-position: top center;
}
.avatarContainer {
  margin-right: 5px;
}

.optionContainer,
.privacyOptionContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  font-weight: 400;
  font-size: 14px;
}

.lastItem {
  color: $hive-danger-red;
}

.remove {
  color: $hive-danger-red;
  font-weight: 400;
  margin-left: auto;
  font-size: 14px;
}

.leaveIcon {
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
}

.leaveGroupContainer {
  display: flex;
  align-items: center;
  color: $hive-danger-red;
  gap: 8px;
  font-weight: bold;
  margin-top: 16px;
  margin-left: 10px;

  > span {
    cursor: pointer;
  }

  > img {
    cursor: pointer;
  }
}
