
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.primary {
  :global(.ant-tooltip-inner) {
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    background: $hive-primary-blue;
  }
  :global(.ant-tooltip-arrow-content) {
    background: $hive-primary-blue;
  }
}

.primary:dir(rtl) {
  :global(.ant-tooltip-inner) {
    text-align: right;
  }
}

.secondary {
  :global(.ant-tooltip-inner) {
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 8px;
    background: #fff;
    color: $hive-gray;
  }
  :global(.ant-tooltip-arrow-content) {
    background: #fff;
    --antd-arrow-background-color: #fff;
  }
}

.secondary:dir(rtl) {
  :global(.ant-tooltip-inner) {
    text-align: right;
  }
}
