
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding-top: 10px;
  font-weight: 700;
  font-size: 20px;
}

.textBellow {
  color: $hive-gray;
  padding-top: 10px;
  font-size: 16px;
}
.textBellowSecond {
  @extend .textBellow;
  padding-top: 1px;
}

.removeIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
