
          @import "src/scss/styles.scss";
        
.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.label {
  display: inline-block;
  margin-bottom: 10px;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.headerIcons {
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.searchIcon:dir(rtl) {
  right: 16px;
  left: auto;
}

.headerSearchIcon {
  @extend .searchIcon;
  left: 32px;
}

.headerSearchIcon:dir(rtl) {
  @extend .searchIcon;
  left: auto;
  right: 22px;
}

.input {
  border: 2px solid transparent;
  background-color: rgba($hive-white, 0.932);
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 6px;
  transition: all 0.3s ease-in-out;

  &.icon {
    padding-inline-start: 40px;
  }

  &.loader {
    padding-inline-end: 25px;
  }

  &.rounded {
    border-radius: 20px;
  }

  &.border {
    border: 2px solid $hive-light-gray;

    &:focus {
      border: 2px solid $hive-primary-blue;
    }
  }

  &:focus {
    border: 2px solid transparent;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: $hive-disabled-background;
    outline: none;
    pointer-events: none;
  }

  &.loading {
    background-color: $hive-disabled-background;
    outline: none;
    pointer-events: none;
  }
}

.loaderContainer {
  @extend .searchIcon;
  left: auto;
  right: 5px;

  svg {
    position: relative;
    top: 3px;
    left: 0;
  }

  &:dir(rtl) {
    left: 5px;
    right: auto;
  }
}
