
          @import "src/scss/styles.scss";
        
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .searchInput {
    &:focus-visible {
      border-color: $hive-light-blue;
    }
  }
}

.container {
  padding: 32px;
  overflow-y: auto;
  flex-grow: 1;
}

.navigationTop {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.tabsAndButtonContainer {
  position: relative;
}

.createEventButton {
  position: absolute;
  top: 0;
  right: 0;
}

.createEventButton:dir(rtl) {
  position: absolute;
  left: 0;
  right: auto;
}
