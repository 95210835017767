
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.modalContainer {
  max-width: 520px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.buttonWrapper {
  padding: 4px;
}

.modalImage {
  width: 100px;
  margin: 20px;
}

.header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: $hive-gray;
  margin: 0;
  padding: 10px 0;
}

.twoRowText {
  @extend .text;
  padding: 2px 0px;
}

.confirmModalButtonWrapper {
  :global(.ant-btn) {
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
}

.closeButton {
  position: absolute;
  right: -20px;
  top: -35px;
}

.closeButton:dir(rtl) {
  left: -20px;
  right: auto;
}

.closeIcon {
  color: $hive-gray !important;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.linkPreviewContainer {
  overflow-y: scroll;
  max-height: 260px;
  scrollbar-width: none; /* Firefox */
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.linkPreviewContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.linkPreviewTitle {
  font-weight: bold;
}

.linkPreviewImage {
  max-width: 100%;
  max-height: 300px;
}

.noPreviewAvailable {
  font-size: 16px;
  max-width: 100%;
  word-break: break-all;
}
