
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  padding: 32px;
  overflow-y: auto;
  flex-grow: 1;
}

.actionsWrapper {
  display: flex;
  gap: 15px;
  align-items: center;

  .searchInput {
    border-radius: 100px;
    width: 240px;

    &:focus-visible {
      border-color: $hive-light-blue;
    }
  }
}

.navigationTop {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}
