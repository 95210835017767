
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.mainWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
}

.sorryScreenWrapper {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100vh;
  .sorryFormWrapper {
    width: 100%;
    height: 100%;
    background-color: $hive-medium-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sorryFormContainer {
      width: 100%;
      max-width: 547px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      .epicenterLogo {
        max-width: 170px;
        max-height: 220px;
        padding: 40px 10px 40px 0px;
      }
      .epicenterHeading {
        font-family: EudoxusSans-Regular;
        line-height: 1.2;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: left;
      }
      .introText {
        font-size: 24px;
        line-height: 1.2;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 50px;
        text-align: left;
        color: $hive-invite-only-text;
      }
      .whoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 40px 0;
        flex-grow: 1;
        span {
          color: rgba($hive-who-wrapper-span, 0.5);
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
  }
  .sorryImageContainer {
    background-color: $hive-medium-gray;
    position: relative;
    height: 100%;
    .placeholderImage {
      width: 60%;
      position: absolute;
      top: 40%;
      right: 40%;
      transform: translateY(-50%);
      z-index: 1;
      background-size: cover;
      background-position: top center;
    }
  }
}

.sorryScreenWrapper:dir(rtl) {
  .sorryFormWrapper {
    .sorryFormContainer {
      .epicenterHeading {
        text-align: right;
      }

      .introText {
        text-align: right;
      }
    }
  }
  .sorryImageContainer {
    .placeholderImage {
      left: 40%;
      right: auto;
      transform: translateY(-50%) rotateY(180deg);
    }
  }
}
