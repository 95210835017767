
          @import "src/scss/styles.scss";
        
.buttonWrapper,
.reverseButtonWrapper {
  :global(.ant-btn-circle) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .hiveButton,
  .hiveButtonDefault {
    background-color: transparent;
    border-color: $hive-primary-blue;
    color: $hive-primary-blue;
    transition: 100ms ease;
    // 43117 - Community Discussion - Create new discussion Icon changes
    svg {
      shape-rendering: auto !important;
    }

    &:hover {
      background-color: $hive-medium-gray;
      color: $hive-primary-blue;
      border-color: $hive-primary-blue;
      cursor: pointer;
    }

    &:disabled {
      background-color: $hive-disabled-background;
      color: $hive-disabled-color;
      border-color: $hive-disabled-background;

      &:hover {
        background-color: $hive-disabled-background;
        cursor: not-allowed;
      }

      svg path {
        fill: $hive-disabled-color;
      }
    }
  }

  .hiveButtonPrimary {
    background-color: $hive-primary-blue;
    border-color: transparent;
    color: $hive-light-gray;
    span:nth-child(2):dir(rtl) {
      margin-right: 8px;
    }

    svg {
      shape-rendering: auto !important;
    }
    &:hover {
      background-color: $hive-primary-blue;
    }
    &:focus {
      background-color: $hive-primary-blue;
    }
    &:disabled {
      background-color: $hive-disabled-background;
      color: $hive-disabled-color;
      border-color: $hive-disabled-background;
    }
  }

  .hiveButtonLink {
    padding: 2px 5px;
    color: $hive-primary-blue;
  }

  :global(.ant-btn > span[role="img"]:dir(rtl)) {
    margin-left: 8px;
  }

  :global(.ant-btn.ant-btn-link > span[role="img"]:dir(rtl)) {
    margin-left: 8px;
    transform: rotate(180deg);
  }

  .hiveButtonGhost {
    @extend .hiveButtonDefault;
    background-color: transparent;
    &:hover {
      border-color: $hive-primary-blue;
      color: $hive-primary-blue;
    }
  }

  .hiveButtonDashed {
    @extend .hiveButton;
    border-style: dashed;
  }

  .hiveButtonText {
    @extend .hiveButtonLink;
    &:hover {
      background-color: $hive-primary-blue;
      color: $hive-white;
    }
  }

  .danger {
    background-color: $hive-danger-red;
    border-color: $hive-danger-red;
    color: $hive-white;
    &:hover {
      color: $hive-white;
      border-color: $hive-danger-red-hover;
      background: $hive-danger-red-hover;
    }
  }
}

.addIndentLeft {
  margin-left: 10px;
}

.reverseButtonWrapper {
  flex-direction: row-reverse;
}

.hiddenInput {
  display: none;
}

.uploadButton {
  border: 1px solid $hive-primary-blue;
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}

.suffixIconWrapper {
  margin-left: 5px;
  display: inline;
}
