
          @import "src/scss/styles.scss";
        
.container {
  flex-grow: 1;
  height: 266px;
  border-radius: 8px;
  position: relative;
}

.img {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.buttonCopy {
  :global(.ant-btn-circle) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
    background: $hive-white;
    opacity: 0.8 !important;
    border: none;
  }
  svg {
    height: 14px;
    path {
      fill: $hive-grey-black;
    }
  }
}
