
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 550px;
  border-radius: 12px;
  padding: 40px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.danger {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.title {
  text-align: center;
  margin: 0;
}

.subtitle {
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  color: $hive-gray;
  margin-top: 25px;
  margin-bottom: 40px;
}

.close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.close:dir(rtl) {
  left: 24px;
  right: auto;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.closeButton {
  background: none;
  padding: 15px 20px;
  border-radius: 100px;
  color: $hive-primary-blue;
  border: 0.5px solid $hive-primary-blue;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}

.submitButton {
  background: $hive-danger-red;
  padding: 15px 20px;
  border-radius: 100px;
  color: $hive-white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.buttonWrapper {
  margin: 0 5px;
}
