
          @import "src/scss/styles.scss";
        
.editField {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
}

.container {
  padding: 10px 0;
  border-bottom: 1px solid $hive-primary-blue;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  color: $hive-grey-black;
  font-weight: 600;
}

.displayText {
  padding-bottom: 10px;
  font-size: 16px;
  color: $hive-gray;
  font-weight: 400;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
