
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.chat {
  border-radius: 100px;
  width: 95%;
  position: relative;
  background-color: $hive-white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.inputWithAttachment {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 95%;
  position: relative;
  margin-bottom: 20px;
  background-color: rgba($hive-white, 0.55);
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.inputChat:focus {
  outline: none !important;
  border: 0.01 solid rgba($hive-white, 0.55);
}

.inputChat {
  width: 99%;
  padding: 10px 10px;
  border-radius: 100px;
  border: none;
  resize: none;
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
}

.iconContainer:dir(rtl) {
  padding-left: 0;
  padding-right: 3%;
}

.chatButtonWrapper {
  margin-left: 5px;
  :global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 5px;
    .arrowImg {
      margin-bottom: 3px;
    }
    &:disabled {
      background-color: transparent;
      &:hover {
        border: 1px solid $hive-disabled-color;
      }
    }
  }
}

.fileContainer {
  position: absolute;
  padding: 5px 5px;
  border: 0.1px solid $hive-white-silver;
  border-radius: 14px;
  margin-top: 9px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $hive-category-background-color;
}

.mentions {
  position: absolute;
  bottom: 100%;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding: 6px 18px;
  background-color: $hive-white;
  max-height: 280px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid $hive-disabled-background;
  .letter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $hive-white;
    background-color: $hive-dark-letter;
    border-radius: 100%;
  }
  .item {
    display: flex;
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: $hive-grey-black;
    cursor: pointer;
  }
}

.cancelIcon {
  padding-left: 5px;
  cursor: pointer;
}

.buttonSend {
  padding: 0 10px;
}
