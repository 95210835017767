
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.avatarWrapper {
  padding-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: $hive-grey-black;
}

.text {
  max-width: 350px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: $hive-gray;
}

.linkText {
  font-weight: 700;
  color: $hive-primary-blue;
  cursor: pointer;
}

.acceptBtn {
  background-color: $hive-black;
}

.buttons {
  display: flex;
  align-items: center;

  .button {
    &:not(:last-child) {
      margin-right: 10px;
    }
    padding: 0;
  }
}

.icon {
  display: inline-block;
  padding-left: 8px;
}
.iconBeforeTitle {
  display: inline-block;
  padding-right: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
