
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.mainContainer {
  position: relative;
  display: flex;
  background-color: $hive-new-orange;
  margin-top: 93px;
  align-items: center;
  .textWrapper {
    width: 50%;
    flex-shrink: 0;
    padding: 85px 30px 75px 125px;
    color: #1e1e1e;
    .title {
      color: #1e1e1e;
      font-size: 72px;
      font-weight: 700;
      line-height: 80px;
    }
  }
  .imgWrapper {
    width: 40%;
    display: flex;
    flex-shrink: 0;
    align-items: flex-end;
    .img {
      max-width: 100%;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 40px;
  top: 40px;
}

.logo {
  width: 120px;
  height: 100%;
}
