
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.mainWrapper {
  padding: 120px 125px;
  background-color: $hive-category-background-color;
}

.eventsCardContainer {
  margin: 0 -11px;
  display: flex;
  flex-wrap: wrap;
}

.eventsTitleContainer {
  padding-bottom: 37px;
}

.eventsTitle {
  font-size: 48px;
  font-family: Eudoxus Sans;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.96px;
}

.eventCard {
  display: flex;
  flex-direction: column;
  padding: 11px;
  width: 25%;
  height: 100%;
}

.container {
  position: relative;
  background-color: $hive-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
}

.textBottom {
  display: flex;
  flex-direction: column;
  background-color: $hive-white;
  width: auto;
  height: auto;
  padding: 16px 24px;
}

.title {
  @extend .h_6;
  max-height: 42px;
  min-height: 42px;
  margin: 0 0 20px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dateAndTime {
  padding: 0px 0px 8px 0;
  @extend .h_7;
  color: rgba($hive-gray, 1);
}

.authorContainer {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @extend .h_7;
}

.feedBackContainer {
  position: absolute;
  top: 5px;
  right: 5px;
}

.imgWrapper {
  height: 120px;
}

.img {
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-position: top center;
}

// small cards
.smallCardOuterWrapper {
  display: flex;
  width: 33.33%;
  padding: 11px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover .smallCardInnerWrapper {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
}

.smallCardInnerWrapper {
  display: flex;
  height: 84px;
  width: 100%;
}

.leftWrapper {
  width: 83px;
  padding: 17px 18px;
  background-size: cover;
  background-position: top center;
  border-radius: 8px 0 0 8px;
  .smallDate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 14px;
    font-size: 10px;
    line-height: 12px;
    background-color: $hive-primary-blue;
    color: $hive-white;
    border-radius: 3px 3px 0px 0px;
  }
  .bigDate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 34px;
    font-size: 21px;
    line-height: 30px;
    background-color: $hive-white;
    color: $hive-grey-black;
    border-radius: 0px 0px 3px 3px;
  }
}

.rightWrapper {
  width: calc(100% - 83px);
  padding: 16px;
  background-color: $hive-white;
  .name {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $hive-grey-black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .additionalInfo {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $hive-gray;
    .hostedBy {
      padding-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.noUpcomingEvents {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $hive-gray;
}

.loadMoreButton {
  display: flex;
  justify-content: center;
}
