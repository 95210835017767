
          @import "src/scss/styles.scss";
        
.welcomePageButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.joinCommunityButton {
  cursor: pointer;
  border-radius: 100px !important;
  background-color: #01b3c5 !important;
  color: #ffffff !important;
  width: 160px !important;
  height: 48px !important;
  margin-right: 24px !important;
  font-size: 1rem !important;
}

.joinCommunityButton:hover {
  color: #ffffff !important;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

.whatIsHiveButton {
  cursor: pointer;
  color: #1e1e1e !important;
  border-bottom: 1px solid #1e1e1e !important;
  border-radius: 0 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;

  svg {
    color: #1e1e1e !important;
    vertical-align: middle;
  }
}

.whatIsHiveButton:hover {
  color: #1e1e1e !important;
  background-color: $hive-new-orange !important;
  transform: translateY(-2px);
}

.welcomeSectionContainer {
  background-color: $hive-new-orange;
  position: relative;
  height: 100%;
  margin-top: 70px;

  .containerSections {
    height: 100%;
    padding-left: 80px;
  }

  .leftSideTitle {
    font-size: 3rem;
    font-weight: 700;
    color: #1e1e1e;
  }

  .leftSideDescription {
    font-weight: 500;
    font-size: 1.313rem;
    line-height: 1.5;
    color: #1e1e1e;
    margin-bottom: 30px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  position: absolute;
  right: 40px;
  top: 40px;
}

.logo {
  width: 120px;
  height: 100%;
}

.bee {
  position: absolute;
  width: 45%;
  bottom: 20%;
  right: 100px;
}
