
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.dotsImage {
  cursor: pointer;
  border: none;
  background: none;
}

.menuItemContainer {
  padding: 8px 0px;
  position: relative;
  width: 222px;
  background: $hive-white;
  border-radius: 8px;
}

.menuItemContainer:dir(rtl) {
  text-align: right;
}

.menuItem {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  padding: 10px 16px;
  cursor: pointer;
}

.menuItemDelete {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  padding: 10px 16px;
  color: $hive-danger-red;
  cursor: pointer;
}

.collectionSettingsPopup {
  :global(.ant-popover-inner) {
    border-radius: 10px;
  }
}
