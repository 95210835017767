
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.accordionHeader {
  font-weight: bold;
  font-size: 18px;
  :global(.ant-collapse-header) {
    padding: 4px 0 !important;
  }
  :global(.ant-collapse-content-box) {
    padding: 6px 0 !important;
  }
  :global(.ant-collapse-expand-icon) {
    position: relative;
    top: -2px;
  }
  :global(.ant-collapse-header-text) {
    font-size: 20px;
  }
}

.accordionHeader:dir(rtl) {
  :global(.ant-collapse-expand-icon) {
    position: relative;
    top: 5px;
    transform: rotate(180deg);
    left: -5px;
  }

  :global(.ant-collapse-item-active > div > .ant-collapse-expand-icon) {
    transform: rotate(0deg);
    top: 0px;
    left: 8px;
  }
}

.panel {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  @include breakpoint(xl) {
    flex-direction: column;
  }
}

.memberSection {
  width: 49%;
  @include breakpoint(xl) {
    width: 100%;
  }
}

.memberButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.memberBtn {
  :global(.ant-btn-link) {
    color: $hive-pure-red;
  }
}

.line {
  height: 18px;
  width: 0.5px;
  border: 1px solid $hive-gray;
}

.sectionHeader {
  border-bottom: 2px solid $hive-disabled-background;
  padding-bottom: 5px;
}

.scrollBox {
  max-height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  padding-top: 16px;
}

.scrollBox::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.info {
  position: relative;
  min-width: 0;

  .avatar {
    width: 35px;
    height: 35px;
    margin-bottom: 20px;
    margin-right: 10px;
    border-radius: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: top center;
    position: absolute;
    left: 0;
  }
  .infoText {
    line-height: 22px;
    letter-spacing: 0;
    margin-left: 45px;
  }

  .infoMemberName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }

  .infoMemberOccupation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    color: $hive-gray;
    margin: 0;
    letter-spacing: -0.5px;
    cursor: default;
  }
}

.info:dir(rtl) {
  .avatar {
    right: 0;
    left: auto;
    margin-right: 0;
  }

  .infoText {
    margin-right: 45px;
    margin-left: auto;
  }
}

.actions {
  display: flex;
  margin-left: -5px;
  .adminBtn,
  .memberBtn {
    padding: 0;
  }
}

.actions:dir(rtl) {
  margin-right: -5px;
}
