
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.sidebar {
  width: 30%;
  background-color: $hive-light-gray;
  z-index: 3;
  position: relative;
}

.detailsContainer {
  position: relative;
  background-color: $hive-medium-gray;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  overflow-y: auto;
  overflow-x: hidden;
}
