
          @import "src/scss/styles.scss";
        
.notificationContainer {
  // This part overrides default `react-toastify` variables
  // Leaving it like this since inheriting colors from `_palette.scss` isn't working
  --toastify-color-error: #f5a3a2;
  --toastify-text-color-error: #21272a;
  --toastify-color-success: #48da8a;
  --toastify-color-info: #00acfa;
  .notificationToast {
    top: 4em;
    min-height: fit-content;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .notificationBody {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}

.notificationContainer:dir(rtl) {
  .notificationToast {
    flex-direction: row-reverse;
  }

  .notificationBody {
    text-align: right;
  }
}
