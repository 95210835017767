
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  height: 90px;
  background-color: $hive-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 67px;
  padding: 10px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-size: cover;
    background-position: top center;
    cursor: pointer;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.hostTitle {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: $hive-gray;
}

.hostName {
  font-size: 15px;
  font-weight: 700;
  color: $hive-grey-black;
  cursor: pointer;
}
