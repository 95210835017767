
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.communitiesGridContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.communitiesGridWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.loadMoreButton {
  align-self: center;
}

.sectionTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1.5px;
  text-align: left;
  margin: 20px 0;
}

.userInterests {
  display: flex;
}

.interestPill {
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 16px;
  border: 1px solid $hive-white-silver;
  color: $hive-black;
  margin-right: 10px;
}

.loadingWrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
