
          @import "src/scss/styles.scss";
        
.emoji {
  position: absolute;
  bottom: 100%;
  right: 30px;
  display: flex;
  justify-content: flex-end;

  :global(.emoji-picker-react) {
    :global(.content-wrapper) {
      &::before {
        display: none;
      }
    }
  }
}

.emoji:dir(rtl) {
  right: auto;
  left: 40px;
}
