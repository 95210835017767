
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.card {
  background: $hive-white;
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  position: relative;
  overflow: hidden;
  width: auto;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }

  .title {
    @extend .h_5;
    color: $hive-grey-black;
    display: -webkit-box;
    height: 47px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      display: -webkit-box;
      height: 47px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.memberCount {
  @extend .h_8;
  color: $hive-backdrop-background;
  height: 27px;
  padding-top: 5px;
  margin: 0 0 0 3px;
}

.cardBottom {
  padding: 0 16px 6px 16px;
  position: relative;
}

.bottomRow {
  display: flex;
  margin-top: 0;
  padding-top: 14px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.subRow {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 45.5px;
  border: 3px solid $hive-white;
  background-color: $hive-category-background-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .img {
  flex-shrink: 0;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-right: 4px;
  border: 1px solid $hive-white;
  background-size: cover;
  background-position: top center;
}

.button {
  border: 1px solid $hive-light-select-blue;
  box-shadow: 0px 4px 3px 0px rgba($hive-black, 0.02);
  background-color: rgba($hive-black, 0.01);
  color: $hive-light-select-blue;
}

.coverImg {
  max-height: 77px;
  min-height: 77px;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: top center;
}

.topRow {
  display: flex;
  position: relative;
  top: -28px;
}

.bannerImg {
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  margin-right: 8px;
  background-color: $hive-category-background-color;
  border: 3px solid $hive-white;
  background-size: cover;
  background-position: top center;
  border-radius: 100%;
}

.emptyButton {
  height: 52px;
}

.communityType {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $hive-disabled-background;
  border-radius: 100%;
}

.inUserDrawer {
  flex-direction: row;
  width: 100%;
  margin: 0;
  .topRow {
    top: 0;
    right: 40px;
    padding-top: 16px;
  }
  .coverImg {
    max-height: none;
    min-height: initial;
    width: 30%;
    height: auto;
  }
  .mainImage {
    max-height: none;
    min-height: initial;
  }
  .cardBottom {
    width: 70%;
  }
  .textHeightInner {
    height: auto;
    min-height: initial;
  }
  .title {
    height: 47px;
    margin: 0 0 20px 0;
    padding-top: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
