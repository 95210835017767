
          @import "src/scss/styles.scss";
        
.peopleInner {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 24px 0 0;
  margin: 0 4px 0 0;
}

.smallAvatar {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: top center;

  &:hover {
    box-shadow: 0 0 8px rgb(0 0 0 / 60%);
    transform: perspective(500px) translateZ(3px);
  }
}

.textOnModal {
  display: flex;
  align-items: center;
  margin: 0;
}

.text {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: $hive-gray;
}

.dotMargin {
  margin: 0 2px;
}
