
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.articlesGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding-left: 22px;
  padding-right: 22px;
}

.container {
  padding: 20px 20%;
  overflow-y: auto;
}

.title {
  @extend .h_1;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: $hive-primary-blue;
  border-radius: 12px;
  margin-top: 10px;
  padding: 30px 50px;
  color: rgba($hive-white, 1);
}

.summaryTitle {
  font-weight: 700;
  font-size: 20px;
}

.summaryContent {
  padding-top: 20px;
  font-weight: 400;
  font-size: 16px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.content {
  font-size: 16px;
  font-weight: 400;
  img {
    max-width: 100%;
    height: auto;
  }
}

.loadMoreButton {
  justify-content: center;
}
