
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.title {
  @extend .h_6;
}

.container {
  display: flex;
  flex-direction: column;
}

.detailsPage {
  display: flex;
  gap: 5px;
}

.category {
  color: $hive-selection-gray;
}
.notPillCategory {
  color: $hive-selection-gray;
  padding-top: 7px;
}
