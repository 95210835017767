
          @import "src/scss/styles.scss";
        
.container {
  width: 100%;
  background-color: $hive-medium-gray;
  padding: 32px;
  padding-top: 18px;
  overflow-y: auto;
}
