
          @import "src/scss/styles.scss";
        
.DragAndDropUpload {
    .frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-style: dashed;
        border-color: $hive-white-silver;
        padding: 50px 0;
    }

    .uploadIcon {
        max-height: 42px;
    }

    .info {
        display: inline-block;
        max-width: 370px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.5px;
        word-break: break-word;
        color: $hive-gray;
        margin-top: 31px;
    }

    .linkText {
        font-weight: 700;
        color: $hive-primary-blue;
        cursor: pointer;
      }

    .btn {
        display: inline-block;
        padding: 0;
      
        :global(.ant-btn) {
          border: none;
          padding: 0;
        }
      
        :global(.ant-btn):hover {
          background-color: transparent;
        }
    }

    .uploadedImagesWrapper {
        display: flex;
        margin-top: 40px;
    }

    .uploadImagePlaceholder {
        height: 45px;
        margin-right: 20px;
    }

    .imageInfoWrapper {}

    .imageInfoText {
        @extend .h_6;
        color: $hive-disabled-color;

    }

    .imageName {
        color: $hive-gray
    }
}