
          @import "src/scss/styles.scss";
        
.modal {
  max-width: 500px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $hive-grey-black;
}

.buttonBorderless {
  :global(.ant-btn-circle) {
    border: none;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-left: 5px;
}

.linkPreviewTitle {
  font-weight: bold;
}

.linkPreviewImage {
  max-width: 100%;
  max-height: 300px;
}

.noPreviewAvailable {
  font-size: 18px;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.linkPreviewContainer {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.linkPreviewContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
