
          @import "src/scss/styles.scss";
        
.sliderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  > div > div > div {
    margin: 0;
  }
}
.slider {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sliderItem {
  display: block;
}

.slick-arrow {
  display: none !important;
}
