
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 560px;
  min-height: 380px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}

.buttonContainer {
  display: flex;
  padding-top: 20px;
  margin-top: auto;
  margin-left: auto;
  width: 150px;
  justify-content: space-between;
}

.exit {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 30px;
  top: 30px;
  path {
    fill: $hive-gray;
  }
  cursor: pointer;
}

.exit:dir(rtl) {
  left: 30px;
  right: auto;
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 10px;
  background-color: $hive-white;
  padding: 6px 10px 6px 15px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    background-color: $hive-light-gray;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: top center;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 12px;

  .cancelBtn {
    margin-top: 12px;
    cursor: pointer;
  }

  .spinner {
    margin-top: 10px;
  }

  p {
    margin-bottom: -10px;
  }
}

.selectWrapper {
  position: relative;
}

.usersContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 20px;
  border: 1px solid $hive-disabled-background;
  scrollbar-width: none; /* Firefox */
}

.usersContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.userNameContainer {
  display: flex;
  flex-direction: column;
}

.usersList {
  max-height: 270px;
  min-height: 70px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.usersList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.member {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.role {
  color: $hive-gray;
  font-size: 13px;
  margin-bottom: 0px;
}

.name {
  margin-bottom: 0px;
}

.userDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.role {
  color: $hive-gray;
  font-size: 13px;
  line-height: 14px;
}

.rightSideContainer {
  display: flex;
}

.exitRed {
  width: 10px;
  height: 10px;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;

  path {
    fill: $hive-danger-red;
  }
}
