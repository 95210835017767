
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
}

.options {
  display: flex;
}

.option {
  padding: 5px 10px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $hive-medium-gray;
  }
}

.iconContainer {
  position: relative;
}
