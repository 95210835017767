
          @import "src/scss/styles.scss";
        
.container {
  overflow-y: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.tabContainer {
  padding: 32px 32px 40px 32px;
}

.breadCrumbsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #e6ecef;
  padding: 16px 32px 22px 32px;
  gap: 12px;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e6ecef;
  padding: 0 32px;
}

.middleBar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #e6ecef;
  padding: 16px 32px;
}

.bottomBar {
  padding: 0 32px 12px 32px;
  background-color: #e6ecef;

  p {
    @extend .h_7;
    color: $hive-gray;
  }
}

.title {
  @extend .h_3;
  margin: 0 80px 0 0;
}

.title:dir(rtl) {
  @extend .h_3;
  margin: 0 0 0 80px;
}

.actionWraper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.uploadButton {
  width: 140px;
  height: 48px;
}

.uploadIcon {
  max-height: 16px;
  margin-right: 8px;
}

.uploadIcon:dir(rtl) {
  margin-right: 0;
  margin-left: 8px;
}

.inputFile {
  display: none;
}

.peopleOuter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  cursor: pointer;
  min-width: 200px;
  color: $hive-primary-blue;
}

.sortContainer {
  padding: 16px 32px 20px 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.sortDropdownWrap {
  min-width: 200px;
  margin: 0 5px 0 0;
}

.gridItems {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
}

.toaster {
  width: 400px;
  margin-left: -80px;
  cursor: default !important;
  display: none !important;

  &.active {
    display: flex !important;
  }
}

.toaster:dir(rtl) {
  transform: translateX(80px);
}

.fileUploadingName {
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadingFileName {
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 6px;
  color: rgba($hive-black, 90%);
}

.fileIcon {
  max-height: 40px;
}

.infoSection {
  width: 250px;
}

.cancelButton {
  color: $hive-primary-blue;
  cursor: pointer;
}

.progress:dir(rtl) {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

:global(.ant-progress-inner):dir(rtl) {
  display: flex;
  justify-content: flex-end;
}
