
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.commentHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.commentHeaderDescription {
  margin-left: 8px;
}

.commentHeaderName {
  @extend .h_6;
  color: $hive-grey-black;
  cursor: pointer;
}

.commentHeaderTime {
  @extend .h_7;
  color: $hive-gray;
  margin: 5px 0 0 0;
}

.commentContent {
  @extend .h_6;
  line-height: 24px;
  font-weight: 500;
  margin: 20px 0 4px 0;
  color: $hive-grey-black;
  white-space: pre-line;
}

.avatar {
  width: 40px;
  height: 40px;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: top center;
  cursor: pointer;
}
