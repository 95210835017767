
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 250px;
  padding: 16px 24px;
  background: rgba($hive-white, 0.6);
  text-align: center;
  margin: 10px 10px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }

  > span .fileName {
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.menuWrapper {
  flex-shrink: 0;
  margin-left: auto;
}

.menuWrapper:dir(rtl) {
  margin-left: 0;
  margin-right: auto;
}

.icon {
  margin-bottom: 15px;
  margin-top: 40px;
  height: 45px;
}

.fileNameInputWrapper {
  padding-bottom: 0px;
}

.fileNameInput {
  width: 100%;
  height: 30px !important;
  border-radius: 4px !important;
  border-color: $hive-disabled-background !important;
  background-color: $hive-category-background-color !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}
