
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.dropdownContainer {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 0.5px solid $hive-primary-blue;
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
}

.spanContainer {
  padding-left: 10px;
  color: $hive-primary-blue;
}
.sortByContainer {
  position: relative;
  z-index: 1;
}

.menuItemWrapper {
  flex-grow: 1;
  width: 190px;
  transition: ease-in 100ms;
  cursor: pointer;
  &:hover {
    background: $hive-sidemenu-item-hover;
    color: $hive-white;
  }
}

.menuItem {
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
}

.buttonWrapper {
  padding: 0;
}
