
          @import "src/scss/styles.scss";
        
@import "../../scss/_palette";

.layout {
	display: flex;
	height: 100%;
	width: 100%;
	font-size: 18px;
}

.pageWrapper {
	padding: 30px;
	flex-grow: 1;
	overflow-y: auto;
}
