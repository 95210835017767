
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";
.item {
  font-size: 20px;
  font-weight: 400;
  color: $hive-gray;
  padding: 10px 10px;
  width: 50%;
  align-self: center;
  justify-self: center;
  text-align-last: center;
  cursor: pointer;
}

.itemSelected {
  font-weight: 600;
  font-size: 20px;
  padding-left: 20px;
  border-bottom: 3px solid $hive-primary-blue;
  padding: 10px 10px;
  width: 50%;
  text-align-last: center;
  cursor: pointer;
}

.container {
  width: 100%;
  padding: 10px;
}

.descriptionContent * {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;

  white-space: pre-wrap;
  line-height: 24px;

  &.externalUrl {
    margin-top: 16px;
  }
}
