
          @import "src/scss/styles.scss";
        
@import "./../../../../scss/styles.scss";

.eventContainer {
  display: flex;
  flex-direction: column;
}

.pastEvents {
  @extend .eventsContainer;
}

.eventCard {
  flex-basis: 25%;
  min-width: 0;
}