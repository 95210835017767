
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.textContainer {
  display: flex;
  flex-direction: column;
  margin-top: 7%;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 17px;
}

.subheading {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $hive-gray;
}
