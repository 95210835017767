
          @import "src/scss/styles.scss";
        
.quill {
  .ql-toolbar {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: white;
  }
  .ql-container {
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .ql-editing {
    z-index: 99;
    margin-left: 110px;
  }
  .ql-snow .ql-tooltip {
    z-index: 2;
  }
  .ql-editor * {
    user-select: auto !important;
    -webkit-user-select: auto !important;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 14px;
  }
}

.quill:dir(rtl) {
  .ql-editor {
    direction: rtl;
    text-align: right;
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    left: 0;
    right: auto;
  }
}
