
          @import "src/scss/styles.scss";
        
.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 64px; 
  margin: 0 auto;
}

.title {
  @extend .h_2;
  margin: 0 0 24px 0;
  text-align: center
}

.subTitle {
  @extend .h_5;
  text-align: center;
  max-width: 600px;
}

.image {
  margin: 0 0 64px;
  max-width: 200px;
}

.extraContent {
  margin: 32px auto 0 auto;
  display: flex;
}