
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @extend .h_2;
  padding-top: 30px;
  letter-spacing: -2.5;
}
.textContainer {
  @extend .h_6;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $hive-gray;
  padding-top: 20px;
}

.button {
  background: $hive-primary-blue;
  color: $hive-white;
  border: 1px solid $hive-primary-blue;
  padding: 5px 10px;
  border-radius: 20px;
  margin-top: 20px;
  cursor: pointer;
}
