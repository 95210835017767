
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.communityContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 32px;
  overflow-y: auto;
}

.communityContent {
  padding-top: 40px;
}

.row {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.route {
  padding: 20px;
}

.column {
  padding-top: 15px;
  width: 70%;
}

.columnExpanded {
  width: 100%;
}

.columnTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $hive-black;
}

.columnContainer {
  margin-right: 20px;
  border-bottom: 1px solid $hive-white-silver;
}

.columnButton {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: $hive-primary-blue;
  cursor: pointer;
}

.myCommunityContainer {
  display: flex;
  flex-direction: column;
}

.myCommunityText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
  color: $hive-black;
  word-break: break-word;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 100px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.interestsContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.interest {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 30px;
  background-color: rgba($hive-white, 0.35);
  padding: 10px 20px;
  margin: 2px;
  border: 1px solid $hive-pure-grey;
  font-weight: 400;
  color: rgba($hive-grey-black, 1);
}

.button {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  background-color: rgba($hive-white, 0.35);
  padding: 10px 20px;
  margin: 2px;
  border: 1px solid $hive-primary-blue;
  font-weight: 400;
  color: $hive-primary-blue;
  align-items: center;
  justify-content: center;
  display: flex;
}

.image {
  margin-right: 10px;
}

.postContainer {
  cursor: pointer;
}

.communityHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.communityActions {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
}

.searchInput {
  border-radius: 100px;
  width: 250px;
}

.createCommunityButtonWrapper {
  padding: 0;
  align-items: flex-start;
}

.columnTitle {
  margin-top: 40px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $hive-black;
}

.communityLinkButtons {
  padding: 0;
  :global(.ant-btn-link) {
    padding: 0;
  }
}
