
          @import "src/scss/styles.scss";
        
.mapStyle {
  height: 830px;
  width: 150vw;
  margin-top: -20%;
  margin-left: -109%;
}

.notClickable {
	pointer-events: none;
}

.mapSettings {
  margin-bottom: 20px;
}

.zoomIn {
  height: 30px;
  width: 30px;
}

.zoomOut {
  height: 30px;
  width: 30px;
}

.zoomButtons {
  position: absolute;
  flex-direction: column;
  display: flex;
  right: 10%;
  top: 50%;
  height: 65px;
  justify-content: space-between;
  transform: translateY(-50%);
}
