
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
}

.buidingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $hive-medium-gray;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  background-image: url(../../images/building_profile.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 60%;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 17px;
  color: $hive-grey-black;
}

.subheading {
  font-size: 16px;
  font-weight: 400;
  color: $hive-gray;
}
