
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.modalContainer {
  background-color: $hive-white !important;
  max-width: 520px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.buttonWrapper {
  padding: 4px;
}

.videoImage {
  width: 100px;
  margin: 20px;
}

.header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 10px 0;
  margin: 0;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: $hive-gray;
  margin: 0;
  padding: 10px 0;
}

.confirmModalButtonWrapper {
  :global(.ant-btn) {
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
}

.closeButton {
  position: absolute;
  right: -40px;
  top: -45px;
}

.closeIcon {
  color: $hive-gray !important;
}
