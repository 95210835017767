
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  height: 266px;
  width: 40%;
  background-color: $hive-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionButton {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: transparent;
  color: $hive-primary-blue;
  transition: 100ms ease;
  border-radius: 50%;
  border: 1px solid $hive-primary-blue;
  box-shadow: 0 2px 0 rgba($hive-black, 2%);
  cursor: pointer;
  &:last-of-type {
    margin-right: 20px;
  }
  &:hover {
    background-color: $hive-medium-gray;
    color: $hive-primary-blue;
    border-color: $hive-primary-blue;
    cursor: pointer;
  }
  img {
    display: block;
    max-height: 20px;
  }
}

.rowSecond {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.img {
  margin-bottom: 10px;
  margin-right: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnSecond {
  display: flex;
  flex-direction: column;
}

.timeTitle {
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-grey-black;
}

.time {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.description {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-gray;
}

.border {
  margin-right: 20px;
  border: 1px solid $hive-white-silver;
}

.button {
  flex-basis: 100%;
}

.eventType {
  @extend .description;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}
