
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.modal.modal {
  background-color: $hive-category-background-color;
}

.introInnerWrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.modalSubtitle {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.eventsRegisterForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputContainer {
  width: calc(50% - 8px);
  input {
    border: none !important;
  }
}

.inputContainerFull {
  width: 100%;
}

.selectContainer {
  width: calc(50% - 8px);
}

.selectRegister {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 5px;
}

.buttonWrapper {
  padding-right: 20px;
}

.errorMessage {
  color: $hive-danger-red;
}
