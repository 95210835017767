
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

@mixin basicText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.outer {
  display: flex;
  flex-direction: column;
  background: rgba($hive-white, 0.6);
  border-radius: 12px;
  color: $hive-black;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
  height: 230px;
  padding: 16px;
  margin: 10px;
}

.userHeader {
  display: flex;
  cursor: pointer;
  gap: 10px;
}

.name {
  font-weight: bold;
}

.userRole {
  color: $hive-gray;
}

.avatarIcon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-size: cover;
  background-position: top center;
  flex-shrink: 0;
}

.locationRow {
  margin-top: 20px;
  color: $hive-gray;
  display: flex;
}

.bio {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  color: $hive-gray;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
