
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.textSection {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  padding: 0 30px;
}

.textSection:dir(rtl) {
  text-align: right;
}

.documentContainer {
  background-color: $hive-white;
  padding: 32px 0 32px 0;
}

.sectionTitle {
  color: $hive-black;
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
}

.paragraph {
  margin-top: 20px;
}

.documentTable {
  border: 1px solid;
  border-color: $hive-medium-gray;
  border-collapse: collapse;
  writing-mode: horizontal-tb;
  table-layout: fixed;
  margin: 30px 0;
  th,
  tr,
  td {
    border: 1px solid;
    border-color: $hive-medium-gray;
    padding: 15px;
    .paragraph {
      margin: 0;
    }
  }
  th {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

.bannerImage {
  margin: 20px 0;
  width: 100%;
}

.listItem {
  .paragraph {
    margin: 10px 0;
  }
}
