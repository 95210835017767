
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.searchChat {
  background-color: rgba($hive-white, 0.55);
  padding: 10px 10px;
  border-radius: 100px;
  height: 40px;
  width: 200px;
  border: 0;
  padding-left: 45px;
}

.headbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid $hive-header-color;
}

.title {
  font-weight: 700;
  font-size: 20px;
  padding-right: 10px;
  word-break: break-word;
}

.image {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.arrowImg {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  margin-left: 8px;
}
