
          @import "src/scss/styles.scss";
        
.sectionTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $hive-grey-black;
  margin-left: 10px;
}

.managedByMe {
  position: relative;
  max-height: 60vh;
  min-height: 350px;
  overflow-y: auto;
  margin-top: 24px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 12px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.managedByMe::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.noManagedByMeText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $hive-gray;
  width: 60%;
}

.emptyManagedByMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadMoreButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 0 10px 0;
}
