
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba($hive-backdrop-background, 0.5);
  z-index: 1101;
}

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  min-width: 575px;
  height: 100%;
  background-color: $hive-drawer-background;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 37px;
  word-wrap: break-word;
  overflow: auto;
  z-index: 1101;
}

.drawer:dir(rtl) {
  left: 0;
  right: auto;
}

@media (max-width: 768px) {
  .drawer {
    width: 100%;
    min-width: initial;
  }
}
