
          @import "src/scss/styles.scss";
        
.communityCollectionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.communityCollectionCardContainer {
  display: flex;
  flex-direction: column;
}

.loadMoreButton {
  align-self: center;
  padding: 80px 0 10px 0;
}
