
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  background-color: $hive-grey-white;
  height: 100%;
  width: 220px;
}

.backButtonContainer {
  display: flex;
  justify-content: center;
  padding: 5px 0 20px 0;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.content {
  padding: 0 10px;
}

.allDiscussions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  gap: 10px;
}

.allDiscussionsSpan {
  cursor: pointer;
}

.activeAllDiscussionsSpan {
  color: $hive-new-blue;
  cursor: pointer;
}

.threeDotsButton {
  margin-left: 15px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.annoucments {
  margin-top: 10px;
  color: $hive-gray;
}

.channels {
  margin-top: 20px;
  max-height: 450px;
  overflow: auto;
  scrollbar-width: thin;
}

.newDisccusion {
  padding: 10px 10px;
  border: 1px $hive-primary-blue solid;
  border-radius: 100px;
  font-size: 14px;
  color: $hive-primary-blue;
  background-color: $hive-grey-white;
  cursor: pointer;
}

.newDiscussionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.menuItemWrapper {
  flex-grow: 1;
  width: 190px;
  transition: ease-in 100ms;
  cursor: pointer;

  &:hover {
    background: $hive-sidemenu-item-hover;
    color: $hive-white;
  }
}

.menuItem {
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
}

.moreMenu {
  :global(.ant-btn-circle) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
