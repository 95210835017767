
          @import "src/scss/styles.scss";
        
.link {
  position: relative;
  right: 1%;
}

// prevent overflow of page when link is too big
:global(.ant-tooltip-inner) {
  max-height: 300px;
  overflow: auto;
}
