
          @import "src/scss/styles.scss";
        
.resultsCount {
  margin: 0;
}

.resultsSearchTerm {
  @extend .h_2;
}

.sectionControls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 5px 10px;
  justify-content: flex-end;
}

.headingContainer {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.suggestRow { 
  @extend .row, .h_7;
  justify-content: space-between;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: $hive-gray;
  margin-bottom: 10px;
}

.rowContainer {
  width: 100%;
}

.button {
  &:global(.ant-btn) {
    border: none;
    padding: 0 5px;
  }
} 