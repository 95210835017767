
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.headerContainer {
  padding: 1.5rem 2.625rem;
  width: 100%;
}

.logo {
  cursor: pointer;
  height: 30px;
}

.rowContainer {
  background-color: white;
  padding: 0 10px 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.menu {
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  color: #ffffff;
  width: 100%;

  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}

.leftSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
}

.leftSection > span {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
