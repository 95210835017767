
          @import "src/scss/styles.scss";
        
@import "./../../../../scss/styles.scss";

.eventContainer {
  display: flex;
  flex-direction: column;
}

.upcomingEvents {
  @extend .eventsContainer;
}

.eventCard {
  flex-basis: 25%;
  min-width: 0;
}

.noUpcomingEvents {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
