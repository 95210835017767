
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.highLighted {
  color: $hive-primary-blue;
  background-color: rgba($hive-primary-blue, 0.3);
}

.mentionedOption {
  color: $hive-primary-blue;
  font-weight: 600;
  cursor: pointer;
}

.inlineLink {
  display: inline-flex;
  padding: 0;
  button {
    &:global(.ant-btn) {
      &:global(.ant-btn-link) {
        padding: 0;
        height: min-content;
      }
    }
  }
} 