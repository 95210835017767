
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.contentContainer {
  padding: 0 30px;
}