
          @import "src/scss/styles.scss";
        
.eventsContainer {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 30px;
  overflow-y: auto;
}
