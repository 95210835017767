
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.container {
  height: 152px;
  border-radius: 12px;
  padding: 32px 24px;
  background-color: rgba($hive-white, 0.6);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  padding-right: 20px;
  min-width: 25%;
  cursor: pointer;
}

.interestsRow {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $hive-white-silver;
  padding-left: 30px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  flex-direction: row;
  max-height: 100px;
  overflow-y: auto;
}

.interest {
  cursor: pointer;
  border-radius: 30px;
  background-color: rgba($hive-white, 0.4);
  padding: 5px 10px;
  margin: 2px;
  border: 1px solid $hive-white-silver;
  font-weight: 400;
  color: $hive-grey-black;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  justify-content: center;
}

.buttonDecline {
  background-color: rgba($hive-white, 0.6);
  margin-left: 5px;
  border: 1px solid $hive-primary-blue;
  border-radius: 100px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  color: $hive-primary-blue;
  height: 40px;
}

.buttonAccept {
  background-color: $hive-primary-blue !important;
  margin-left: 5px;
  color: $hive-white !important;
  border: 1px solid $hive-primary-blue;
  border-radius: 100px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  height: 40px;
}

.icon {
  margin-right: 10px;
}

.avatarImage {
  width: 40px;
  margin-bottom: 45px;
  cursor: pointer;
}

.fullName {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
}

.role {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: $hive-grey-name;
}

.interestTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}
