
          @import "src/scss/styles.scss";
        
.loadMoreButton {
  padding: 80px 0 10px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.plusIcon {
  margin-right: 10px;
}

.communityCardInUserDrawer {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
