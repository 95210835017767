
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  padding: 30px;
  overflow: auto;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.firstTitle {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2.5px;
  text-align: left;
}

.firstTitle:dir(rtl) {
  text-align: right;
}

.secondTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-gray;
  margin-top: 8px;
}

.secondTitle:dir(rtl) {
  text-align: right;
}

.cardsContainer {
  padding-top: 80px;
  padding-left: 20px;
}

.feedbackCard {
  padding-top: 40px;
}

.cardContainer {
  background-color: rgba($hive-white, 0.55);
  width: 45%;
  border-radius: 6px;
  border: 1px solid $hive-white;
  padding: 48px 48px 38px;
}

.cardTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: $hive-grey-black;
}

.cardContent {
  font-size: 16px;
  font-weight: 400;
  color: $hive-gray;
  margin-top: 16px;
  margin-bottom: 22px;
  p {
    margin-bottom: 0px;
  }
}

.bullet {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  color: $hive-grey-black;
}

.bulletText {
  cursor: pointer;
  color: $hive-primary-blue;
}
