
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.main-app {
  overscroll-behavior: none;
  font-family: "EudoxusSans";

  .header-container {
    padding: 1.5rem 2.625rem;
    width: 100%;
  }

  h1 {
    font-size: 72px !important;
  }
  h2 {
    font-size: 56px !important;
  }
  h3 {
    font-size: 40px !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    color: $hive-white;
    @media screen and (max-width: 768px) {
      font-size: 42px;
    }
  }

  h2.ant-typography,
  .ant-typography h2 {
    font-weight: bold;
    line-height: 121%;
    color: $hive-grey-text;

    @media screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 111.1%;
    }
  }

  h3.ant-typography,
  .ant-typography h3 {
    font-weight: bold;
    color: $hive-grey-text;
    font-size: 40px;
    line-height: 135%;
    @media screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 111%;
    }
  }

  h4.ant-typography,
  .ant-typography h4 {
    font-weight: 400;
    font-size: 2.25rem;
    color: $hive-grey-text;
  }

  .ant-typography strong {
    font-weight: bold;
  }

  .ant-form-item-label > label {
    font-weight: bold;
    color: $hive-white;
    font-size: 1rem;
  }

  .ant-select-dropdown {
    border-radius: 10px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $hive-primary-blue;
    background-color: $hive-primary-blue;
  }

  .ant-collapse-content-box {
    padding: 0 16px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 800;
    font-size: 1.25rem;
    color: $hive-white;
  }

  .ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header {
    font-weight: bold;
  }

  .ant-collapse-content {
    font-weight: 100;
    color: $hive-collapse-content;
    line-height: 24px;
    font-size: 1rem;
    padding: 0;
  }
  .ant-collapse-item {
    position: relative;
    overflow: hidden;
  }

  .ant-collapse-item:before {
    content: "";
    position: absolute;
    width: 2px;
    left: 0;
    top: 15px;
    background-color: $hive-white;
    z-index: 2;
    transition: 10s transform linear;
    bottom: 10px;
    transform: scaleY(0);
    transform-origin: top;
  }
  .ant-collapse-item:after {
    content: "";
    width: 2px;
    position: absolute;
    left: 0;
    top: 15px;
    bottom: 10px;
    background-color: $hive-white;
    z-index: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: 0.2s all linear;
  }

  .ant-collapse-item-active.ant-collapse-item:before {
    transform: scaleY(1);
  }

  .ant-collapse-item-active.ant-collapse-item:after {
    transform: scaleY(1);
    transition: 1s transform linear;
  }

  .ant-collapse-header {
    position: relative;
    padding: 12px 16px !important;
  }

  .ant-carousel .slick-dots li,
  .dot {
    width: 11px;
    height: 11px;
    background: $hive-carousel-dot;
    border-radius: 50%;
  }

  .dot.purple {
    background: $hive-carousel-dot-selected;
  }
  .dot.grey {
    background: $hive-carousel-dot;
  }

  .ant-checkbox-checked::after {
    border-width: 0;
  }

  .ant-form-item-has-error .ant-input {
    box-shadow: 0 0 1px 1px $hive-danger-red;
  }
  .subtitle {
    font-size: 48px !important;
  }
  .head-title {
    font-size: 88px !important;
    margin-bottom: 32px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: $hive-white;
    box-shadow: 0 0 1px 2px $hive-select-blue;
  }

  .ant-progress-circle .ant-progress-inner {
    @media screen and (max-width: 768px) {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.ant-select-dropdown {
  border-radius: 17px !important;
}
.ant-select-item {
  min-height: 0 !important;
  line-height: 2 !important;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  color: $hive-white;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-color: $hive-white;
}
