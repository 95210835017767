
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: column;
}

.buttonText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: $hive-primary-blue;
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}

.avatarImg {
  margin-top: 40px;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: top center;
}

.infoContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 0 10px 0;
}

.fullNameContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  .titleName {
    @extend .h_3;
  }

  .pronouns {
    @extend .h_6;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  height: 32px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.locationRow {
  display: flex;
  margin-left: -3px;
  margin-top: 8px;
  gap: 18px;
  margin-bottom: 16px;
}

.locationRow:dir(rtl) {
  margin-right: -3px;
  margin-left: 0px;
}

.titleOrganization {
  @extend .h_6;
  font-weight: 400;
  color: $hive-gray;
}

.titleInterest {
  @extend .h_4;
  margin: 24px 0 12px 0;
}

.userInterests {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
}

.interest {
  @extend .h_7;
  cursor: pointer;
  border-radius: 30px;
  padding: 6px 15px;
  margin: 2px;
  font-weight: 400;
  color: $hive-grey-black;
  background-color: rgba($hive-white, 0.6);
}

.img {
  margin-right: 5px;
}

.buttonWrapper {
  padding: 0;
}

.sendMessage {
  padding: 0;
}

.allResourcesBtnWrapper {
  :global(.ant-btn) {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
  }
}

.communityCardInUserDrawer {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}

.interestsTitle {
  padding-top: 16px;
}

.interests {
  color: $hive-gray;
  margin: 16px 0px;
  line-height: 1;
}

.linkedinIcon {
  width: 40px;
  cursor: pointer;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
