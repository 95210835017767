
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.requestApprovalCard {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  justify-content: space-between;
}

.user {
  display: flex;
}

.title {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.avatarWrapper {
  padding-right: 10px;
  cursor: pointer;
}

.avatarWrapper:dir(rtl) {
  padding-left: 10px;
  padding-right: 0px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-size: cover;
  background-position: top center;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: $hive-primary-blue;
  cursor: pointer;
}

.name:dir(rtl) {
  text-align: right;
}

.text {
  display: inline-block;
  max-width: 370px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  word-break: break-word;
  color: $hive-gray;
}

.text:dir(rtl) {
  text-align: right;
}

.btn {
  display: inline-block;
  padding: 0;
  max-width: 100%;

  :global(.ant-btn) {
    display: flex;
    border: none;
    padding: 0;
    max-width: 100%;
    span {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  :global(.ant-btn):hover {
    background-color: transparent;
  }
}

.linkText {
  font-weight: 700;
  color: $hive-primary-blue;
  cursor: pointer;
}

.time {
  padding-left: 20px;
  font-size: 11px;
}

.time:dir(rtl) {
  padding-left: 0px;
  padding-right: 20px;
}
