
          @import "src/scss/styles.scss";
        
.sectionWrapper {
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: -10px;
}

.infoMessage {
  margin-top: 15px;
}

.filesButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    margin-bottom: 0;
  }
}
