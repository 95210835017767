
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.container {
  @include breakpoint(xl) {
    width: 70%;
  }

  @include breakpoint(md) {
    width: 85%;
  }

  background-color: $hive-white;
}

.heading {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2.5px;
  text-align: center;
}

.subheading {
  display: block;
  margin: auto;
  margin-bottom: 40px;
  max-width: 530px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
}

.grid {
  margin-bottom: 30px;
  padding: 0 20px;
  height: 35vh;
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
}

.row:dir(rtl) {
  flex-direction: row-reverse;
}

.col {
  flex-basis: 100%;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
}

.title:dir(rtl) {
  text-align: right;
  margin-right: 20px;
}

.about {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-black;
  margin-right: 50px;
}

.about:dir(rtl) {
  text-align: right;
  margin-right: 20px;
}

.img {
  width: 100%;
}

.closeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}

.button {
  :global(.ant-btn) {
    color: $hive-white;
    background: $hive-primary-blue;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
}
