
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  width: 60%;
}

.headingContainer {
  margin: 25px 20px 20px 0px;
}

.buttonName {
  font-size: 14px;
  font-weight: 500;
  width: 10%;
  height: 40px;
  margin-top: 7px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 40px;
  margin-left: 20px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid $hive-primary-blue;
}

.rowTitle {
  font-size: 16px;
  color: $hive-grey-black;
  font-weight: 600;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputLabel {
  font-weight: normal;
  font-size: 14px;
}

.value {
  font-size: 16px;
  color: $hive-gray;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.valueLink {
  font-size: 16px;
  color: $hive-primary-blue !important;
  margin-top: 10px;
  padding-bottom: 16px;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: pointer;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-left: 5px;
}

.numberOfViewsInput {
  width: 100px;
  text-align: center;
}

.numberOfViews {
  display: flex;
  align-items: center;
}
