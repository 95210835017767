
          @import "src/scss/styles.scss";
        
.popoverContainer {
  :global(.ant-popover-inner) {
    background-color: $hive-white;
    border-radius: 8px;
  }
}

.moreMenu {
  padding: 0;
  margin-left: 5px;

  :global(.ant-btn) {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }
}
