
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $hive-grey-black;
}

.subtitle {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  padding: 2px;
  margin-bottom: 10px;
  color: $hive-dark-grey;
}

.userName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $hive-grey-black;
}

.userPosition {
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  color: $hive-gray;
}

.cancelButton {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $hive-primary-blue;
  cursor: pointer;
}

.searchBar {
  border-radius: 100px;
  padding: 10px 40px;
  height: initial;
  background: $hive-white;
  width: 100%;
  border: 2px solid $hive-light-gray;
  &:focus {
    border: 2px solid $hive-primary-blue;
  }
  &:focus-visible {
    outline: none;
  }
}

.peopleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $hive-white-silver;
  padding-bottom: 10px;
  padding-top: 10px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid $hive-white-silver;
}

.blueCircle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flexbox;
  background-color: $hive-primary-blue;
  border: 2px solid $hive-primary-blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7%;
}

.buttonCheck {
  height: 48px;
  width: 142px;
  border-radius: 100px;
  padding: 16px 20px 16px 20px;
  color: $hive-white;
  background-color: $hive-primary-blue;
  margin-left: 30px;
}

.closeImage {
  padding-bottom: 40px;
  cursor: pointer;
}

.button {
  height: 48px;
  width: 142px;
  border-radius: 100px;
  padding: 16px 20px 16px 20px;
  color: $hive-disabled-color;
  background-color: $hive-disabled-background;
  margin-left: 30px;
}

.memberItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.memberList {
  margin-top: 30px;
  overflow-y: auto;
}

.memberName {
  font-size: 20px;
  font-weight: 300;
}

.memberPosition {
  font-size: 13px;
  color: $hive-gray;
  font-weight: 300;
}

.errorName {
  font-size: 13px;
  color: $hive-pure-red;
  font-weight: 300;
}

.memberImage {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
}

.checked {
  margin-left: auto;
  width: 40px;
  height: 40px;
}

.checked:dir(rtl) {
  margin-right: auto;
  margin-left: 10px;
}

.unChecked {
  @extend .checked;
  border-radius: 100%;
  border-width: 1px;
  border: 2px solid $hive-white-silver;
}
