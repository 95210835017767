
          @import "src/scss/styles.scss";
        
.mainImage {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 90px;
    display: block;
    margin: 0 auto 14px auto;
  }
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  @extend .h_5;
  color: $hive-grey-black;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 0 auto;
  word-wrap: break-word;
}

.subTitle {
  @extend .h_6;
  font-weight: 400;
  margin: 24px auto 0 auto;
  text-align: center;
  max-width: 450px;
}

.closeImage {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.closeImage:dir(rtl) {
  left: 30px;
  right: auto;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  gap: 10px;
}
