
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  overflow-y: auto;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1.5px;
}

.communityName {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1.5px;
  color: $hive-grey-name;
  margin-left: 3px;
}

.noRequestContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noRequestTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1.5px;
  margin-top: 20px;
}

.noRequestSubtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $hive-gray;
}

.requestsContainer {
  margin-top: 30px;
  overflow-y: scroll;
}
