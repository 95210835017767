
          @import "src/scss/styles.scss";
        
@import "../../../scss/_palette";

.white {
  color: #fff;
}

.goToCollectionIcon {
  vertical-align: middle;
  padding-right: 10px;
}

.myCollectionsContainer {
  padding-top: 20px;
}

.activeButton {
  &:global(.ant-btn) {
    color: $hive-primary-blue;
    background-color: rgba($hive-selected-pill-blue, 0.3);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px 7px;

    &:active {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }

    &:focus {
      background-color: rgba($hive-selected-pill-blue, 0.3);
    }
  }
}

.notActive {
  &:global(.ant-btn) {
    color: $hive-black;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
    text-align: center;
    box-shadow: none;
    padding: 10px 7px;
  }
}

.communityCollectionsTitle {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;
  min-height: 90px;
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.scrollableContainer {
  height: calc(100vh - 350px);
}

.scrollableDiv {
  height: 100%;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 12px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.scrollableDiv::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
