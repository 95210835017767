
          @import "src/scss/styles.scss";
        
.wrapper {
  background-color: $hive-light-gray;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.removeIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.removeIcon:dir(rtl) {
  left: 20px;
  right: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}
