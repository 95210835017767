
          @import "src/scss/styles.scss";
        
.reaction {
  background-color: $hive-dirty-white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  transition: all 0.3s;
  cursor: pointer;

  &.myReaction {
    border: 1px solid $hive-primary-blue;
  }

  &:hover {
    background-color: $hive-light-blue;

    .number {
      color: $hive-category-background-color;
    }
  }
}

.image {
  width: 20px;
  height: 20px;
}

.number {
  line-height: 1.5;
  color: $hive-gray;
  font-size: 14px;
}

.usersContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 10px;
  max-height: 100px;
  font-size: 12px;
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: thin;
}
