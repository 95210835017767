
          @import "src/scss/styles.scss";
        
.chatPage {
  display: flex;
  height: 100%;
  overflow: auto;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
  height: 100%;

  .messenger {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    .messages {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
    }

    .message {
      width: 100%;
    }

    .input {
      position: relative;
      z-index: 2;
      width: 100%;
    }
  }
}

.attachmentBox {
  margin-left: 10px;
  background-color: rgba($hive-white, 0.75);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: 1px solid $hive-medium-gray;
  display: flex;
  height: 80px;
  width: 95%;
  padding: 10px;
  overflow-y: auto;
}

.parentMessageBox {
  margin-left: 10px;
  background-color: $hive-white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  width: 95%;
  padding: 12px 16px;
  box-shadow: 0 30px 0px 0px $hive-white;
  overflow-y: auto;
}
