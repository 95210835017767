
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  > div {
    width: 100% !important;
  }
}

.table {
  width: 100%;

  :global(.ant-table) {
    background-color: transparent !important;
  }
  thead {
    th {
      background-color: transparent !important;
    }

    th:dir(rtl) {
      text-align: right;
    }
  }

  td {
    padding: 12px !important;
    background: rgba($hive-white, 0.6) !important;
  }

  td:dir(rtl) {
    text-align: right;
  }

  :global(.ant-pagination-item-active) {
    border-radius: 100px !important;
    background-color: $hive-primary-blue !important;
    color: $hive-white !important;
    a {
      color: $hive-white !important;
    }
  }
  li {
    border: none !important;
    background-color: transparent !important;

    :global(.ant-select-selector) {
      background-color: transparent !important;
      border: none !important;
    }
    :global(.ant-select-arrow) {
      background-color: transparent !important;
      border: none !important;
      color: $hive-black !important;
    }

    button {
      border: none !important;
      background-color: transparent !important;
    }
    a {
      color: $hive-grey-name !important;
    }
  }
}

.cursor {
  td {
    cursor: pointer;
  }
}

.pagination {
  display: flex;
}
