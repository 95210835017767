
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: row;
}

.contentWrapper {
  background-color: $hive-medium-gray;
  width: 100%;
}

.content {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.checkbox {
  padding: 10px 0;
}

.button {
  background-color: $hive-primary-blue;
  border-radius: 30px;
  color: $hive-white;
  width: 120px;
  height: 40px;
  border: none;
  margin-left: auto;
  padding: 0 30px;
  cursor: pointer;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  margin-top: 7%;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 17px;
}

.subheading {
  font-size: 16px;
  font-weight: 400;
  color: $hive-gray;
}

.buttonContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  margin-top: 15px;
  position: relative;
}

.searchInterests {
  border-radius: 100px;
  border: 2px solid transparent;
  background-color: rgba($hive-white, 0.55);
  height: 48px;
}

.searchLabel {
  font-size: 16px;
  font-weight: 600;
  color: $hive-grey-black;
  margin-bottom: 10px;
}

.searchIcon {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.nextButton {
  position: relative;
  right: 20px;
  bottom: 33%;
  width: 100px;
}

.input {
  padding-top: 30px;
  position: relative;
}

.searchImage {
  position: absolute;
  transform: translateY(-35%);
  top: 70%;
  left: 15px;
  z-index: 2;
  pointer-events: none;
}

.searchInterests {
  border-radius: 100px;
  border: 2px solid transparent;
  background-color: rgba($hive-white, 0.6);
  height: 40px;
  padding-left: 45px;
}

.chosenInterest {
  background-color: $hive-white-silver;
}

.backLink {
  display: flex;
  color: $hive-primary-blue;
  text-decoration: none;
  font-size: 14px;
  img {
    margin-right: 4px;
  }
}
