
          @import "src/scss/styles.scss";
        
.pillsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: ease 1000ms;
}