
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.container {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
}

.communityCard {
  width: 25%;
  height: 306px;
  border-radius: 12px;
}

.searchResult {
  margin-left: 5px;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
}

.noResultsContainer {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgWrapper {
  margin-bottom: 60px;
}
.img {
  width: 100%;
}

.noResultsText {
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  font-size: 36px;
  font-weight: 700;
  color: $hive-grey-black;
}

.infoText {
  font-size: 18px;
  font-weight: 400;
  color: $hive-gray;
}

.viewMoreContainer {
  display: flex;
  justify-content: center;
}
