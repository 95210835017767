
          @import "src/scss/styles.scss";
        
.eventsResourcesContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  margin: 0;
  line-height: 28px;
}

.loaderWrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.communitiesButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.textContainer {
  @extend .h_4;
  margin-top: 24px;
}

.customHeaderTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1.5px;
  text-align: left;
  margin: 0;
}

.eventIcon {
  width: 30px;
}
