
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.sectionWrapper {
  padding-top: 45px;
}

.sectionTitle {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
