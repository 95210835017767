
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.card {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 4px;
  background: rgba($hive-white, 0.6);
  margin: 10px 10px;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
}

.menuWrapper {
  flex-shrink: 0;
  margin-left: auto;
  margin-top: -35px;
}

.icon {
  height: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}

.rtlIcon {
  margin-right: 0px;
  margin-left: 10px;
}

.fileName {
  white-space: normal;
  width: 100%;
  font-size: 12px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
