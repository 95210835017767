
          @import "src/scss/styles.scss";
        
.customTabPane {
  &:global(.ant-tabs-nav) {
    display: flex;
    :global(.ant-tabs-nav-wrap) {
      display: flex;
      :global(.ant-tabs-nav-list) {
        flex-grow: 1;
        display: flex;
        :global(.ant-tabs-tab) {
          &:global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
            color: $hive-primary-blue;
          }
          flex-grow: 1;
          margin: 0;
          justify-content: center;
          border-bottom: 1px solid $hive-primary-blue;
        }
        :global(.ant-tabs-ink-bar) {
          background: $hive-primary-blue;
        }
      }
    }
    :global(.ant-tabs-nav-operations) {
      display: none !important;
    }
  }
  &.customTabButton {
    :global(.ant-tabs-nav-wrap) {
      :global(.ant-tabs-nav-list) {
        border-bottom: 1px solid $hive-primary-blue;
        :global(.ant-tabs-tab) {
          border: none;
          flex-grow: unset;
          padding: 16px;
        }
        :global(.ant-tabs-ink-bar) {
          background: $hive-primary-blue;
        }
      }
    }
  }
}

:global(.ant-tabs-nav-list) {
  :global(.ant-tabs-tab) {
    &:global(.ant-tabs-tab-active) {
      :global(.ant-tabs-tab-btn) {
        color: $hive-primary-blue;
      }
    }
  }
  :global(.ant-tabs-ink-bar) {
    background: $hive-primary-blue;
  }
}
