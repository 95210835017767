
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.eventsContainer {
  display: flex;
  flex-wrap: wrap;
  @extend .itemPadding;
}

.topContainer {
  @extend .tabTitle;
  padding-left: 12px;
  @extend .itemPadding;
}

.container {
  padding: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.backButtonContainer {
  @extend .itemPadding;
}

.itemPadding {
  padding: 0 30px;
}

.eventCard {
  flex-basis: 25%;
  min-width: 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.viewMoreButton {
  color: $hive-primary-blue;
  border: 1px solid $hive-primary-blue;
  border-radius: 30px;
  height: 30px;
  background-color: $hive-light-gray;
  padding: 0 15px;
  cursor: pointer;
}

.pageEnd {
  width: 90%;
  display: block;
  background: $hive-pure-red;
}

.heading {
  padding: 30px 0 0 30px;
}

.subHeading {
  @extend .h_3;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}
