
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.noPendingInvitationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  margin: 0 auto 20px auto;
  width: 100%;
  border-radius: 12px;
  text-align: center;
  background-color: rgba($hive-white, 0.6);
  .noPendingInvitationsImage {
    height: 70px;
    width: 70px;
  }
}