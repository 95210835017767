
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles";

.container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.locationsHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headingContainer {
  margin: 25px 20px 20px 0px;
}

.heading {
  @extend .h_4;
  color: $hive-grey-black;
}

.locationsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.locationsHeading {
  @extend .h_6;
  color: $hive-grey-black;
}

.locationsHeadingButton {
  @extend .h_7;
  cursor: pointer;
  color: $hive-primary-blue;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.location {
  @extend .h_7;
  line-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($hive-white, 0.55);
  color: $hive-grey-black;
  border-radius: 30px;
  border: 1px solid $hive-white;
  padding: 10px 20px;
  margin: 2px;
  cursor: pointer;
}

.mapStyles {
  display: block;
}

.buttonName {
  font-size: 14px;
  font-weight: 500;
  width: 10%;
  height: 40px;
  margin-top: 7px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 40px;
}
