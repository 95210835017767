
          @import "src/scss/styles.scss";
        
.Wrapper {
  :global(.ant-tree) {
    background-color: $hive-category-background-color;
  }

  :global(.ant-tree .ant-tree-treenode) {
    padding: 0;

    :global(.ant-tree-iconEle) {
      margin: 2px 0;
    }

    :global(.ant-tree-iconEle):dir(rtl) {
      margin-left: 4px;
    }
  }

  :global(.ant-tree .ant-tree-treenode.ant-tree-treenode-selected) {
    background-color: #d0dbfb;
  }

  :global(.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected) {
    background-color: transparent;
  }

  :global(.ant-tree .ant-tree-node-content-wrapper) {
    padding: 0;
  }

  :global(.ant-tree-switcher > span > svg):dir(rtl) {
    transform: rotate(90deg);
  }

  :global(.ant-tree-switcher_open > span > svg):dir(rtl) {
    transform: rotate(0deg);
  }

  :global(.ant-tree .ant-tree-title) {
    margin-left: 9px;
    color: $hive-grey-black;
    line-height: 22px;
  }
}
