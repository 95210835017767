
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

* {
  box-sizing: border-box;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $hive-medium-gray;
  overflow: hidden;
  padding: 20px;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  max-height: 644px;
  max-width: 920px;
  border-radius: 12px;
  padding: 50px 50px 0px 50px;
  background-color: $hive-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.codeContainer {
  flex-grow: 1;
  overflow-y: auto;
}

.codeTitle {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2.5px;
  text-align: center;
}

.codeSubtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;  padding: 0 30px;
  margin: 20px 0;
}

.textSection {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-grey-name;
  padding: 0 30px;
  .sectionTitle {
    color: $hive-black;
  }
}

.buttonsContainer {
  width: 100%;
  border-top: 1px solid $hive-white-silver;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.checkboxWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}