
          @import "src/scss/styles.scss";
        
.container {
  width: 100%;
  padding: 100px 80px;
  background-color: #164057;
  position: relative;
}

.title {
  color: #ffffff;
  font-size: 50px;
  line-height: 1.2;
  font-weight: 700;
}

.listItemTitle {
  color: #ffa629;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 8px;
}

.listItemDesc {
  color: #ffffff;
  width: 50%;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 24px;
}

.aboutBg {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 40%;
}
