
          @import "src/scss/styles.scss";
        
@mixin skipArrow() {
  position: relative;
  top: -2px;

  &:dir(rtl) {
    transform: rotate(180deg);
  }
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 40px 0;

  .innerPagination {
    position: relative;
    display: flex;
    align-items: center;
  }

  li {
    margin-bottom: 0;
  }

  :global(.ant-pagination-options) {
    margin-left: 35px;

    &:dir(rtl) {
      margin-left: 0;
      margin-right: 35px;
    }
  }

  :global(.ant-select-selector) {
    background-color: $hive-white !important;
    border-radius: 15px !important;
    border: none !important;
  }

  :global(.ant-pagination-item-link) {
    background-color: $hive-drawer-background !important;
    border: none !important;
  }

  :global(.ant-select-focused) {
    outline: none !important;
  }

  :global(.ant-pagination-item) {
    background-color: $hive-drawer-background !important;
    border: none !important;
  }

  :global(.ant-pagination-item-active a) {
    background-color: $hive-primary-blue !important;
    border-radius: 100px !important;
    color: $hive-white !important;
  }

  :global(.anticon-double-right) {
    @include skipArrow;
  }

  :global(.anticon-double-left) {
    @include skipArrow;
  }

  :global(.ant-pagination-next) {
    &:dir(rtl) {
      transform: rotate(180deg);
    }
  }

  :global(.ant-pagination-prev) {
    &:dir(rtl) {
      transform: rotate(180deg);
    }
  }

  .button {
    &:hover {
      background-color: unset;
    }

    &:hover path {
      fill: $hive-secondary-blue;
    }

    &:disabled path {
      fill: rgba($color: $hive-black, $alpha: 0.25);
    }
  }
}

$pagination-options-width: 95px;
$button-spacing: 10px;

.buttonWrapper {
  padding: 0;

  &:dir(rtl) {
    svg {
      transform: rotate(180deg);
    }
  }

  &.right {
    position: absolute;
    top: 1px;
    right: calc($button-spacing + $pagination-options-width);

    &:dir(rtl) {
      right: auto;
      left: calc($button-spacing + $pagination-options-width);
    }
  }
}
