
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.removeNotification {
  cursor: pointer;
}

.buttons {
  margin-top: 10px;
  padding-left: 50px;
}

.leaveButton {
  :global(.ant-btn-link) {
    padding-left: 50px;
    color: $hive-danger-red;
  }
}
