
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.navbar {
  background-color: $hive-white-background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  max-width: 260px;
  width: 30%;
  padding: 30px;
  padding-right: 10px;
  transition: 0.2s ease-in-out;

  &.collapsed {
    width: 60px;
    padding: 20px 0;
    align-items: center;
  }
}

.logoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .collapseIcon {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
  }

  .collapseIcon:dir(rtl) {
    right: 200px;
    transform: rotate(180deg);
  }

  .logo {
    display: flex;
    justify-content: space-between;
    width: 81px;
  }

  .text {
    color: $hive-gray;
    font-weight: 400;
    min-width: 200px;
  }
}

.logoCollapsedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;

  .hamburgerMenu {
    margin-bottom: 10px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15% 0;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;

  .submenu {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &.collapsed {
    align-items: center;
    padding-bottom: 20px;

    .submenu {
      width: 100%;
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: $hive-gray;
  cursor: pointer;

  &.active {
    color: $hive-grey-black;

    &.primary {
      color: $hive-new-orange;
    }
  }

  .itemContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .itemText {
    white-space: nowrap;
  }

  .icon {
    display: block;
    width: 20px;
    height: 20px;

    path {
      fill: currentColor;
    }
  }

  &.collapsed {
    justify-content: center;

    .itemText {
      display: none;
    }
  }
}

.whoLogo {
  cursor: pointer;
  max-height: 40px;
}

.whoLogoCollapsed {
  cursor: pointer;
  height: 40px;
}
