
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.eventContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.eventCard {
  flex-basis: 25%;
  min-width: 0;
}

.searchedEvents {
  @extend .eventsContainer;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.headingContainer {
  padding: 0 12px;
}

.heading {
  display: flex;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -2.5px;
}

.title {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4.5px;
}

.caption {
  padding-left: 5px;
}

.noResultsContainer {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgWrapper {
  margin-bottom: 60px;
}

.img {
  width: 100%;
}

.noResultsText {
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  font-size: 36px;
  font-weight: 700;
  color: $hive-grey-black;
}

.infoText {
  font-size: 18px;
  font-weight: 400;
  color: $hive-gray;
}
