
          @import "src/scss/styles.scss";
        
.uploadModalWrapper {
  &:global(.ReactModal__Content) {
    background-color: $hive-light-gray;
  }
}

.removeIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.removeIcon:dir(rtl) {
  left: 20px;
  right: auto;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
  min-height: 0;
  padding: 5px 5px;
}

.firstTitle {
  font-size: 20px;
  font-weight: 700;
}

.secondTitle {
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-style: dashed;
  border-color: $hive-white-silver;
  border-width: 1px;
  padding: 70px 20px;
  margin-top: 20px;
}

.textContainer {
  padding-top: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.cancelButton {
  font-size: 16px;
  color: $hive-primary-blue;
  font-weight: 400;
  cursor: pointer;
}

.attachment {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.link {
  color: $hive-primary-blue;
  text-decoration: 2px underline;
  cursor: pointer;
}

.inputFile {
  display: none;
}

.fileContainer {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
}

.fileBoxContainer {
  min-height: 0;
  flex-grow: 1;
  overflow: auto;
  padding-right: 15px;
}

.fileName {
  font-size: 16px;
  font-weight: 400;
}

.size {
  color: $hive-gray;
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.sizeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconCancel {
  cursor: pointer;
  width: 12px;
  height: 12px;
}
