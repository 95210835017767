
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

@mixin basicText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.outer {
  background: rgba($hive-white, 0.6);
  border-radius: 12px;
  color: $hive-black;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
}

.textInner {
  padding: 16px 24px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.mainImage {
  display: flex;
  align-items: self-start;
  justify-content: flex-end;
  max-height: 120px;
  min-height: 120px;
  height: 100%;
  width: 100%;
  padding: 12px;
  background-size: cover;
  background-position: top center;
  cursor: pointer;
}

.dot {
  margin: 0 3px;
}

.textHeightInner {
  display: flex;
  flex-direction: column;
}

.title {
  @extend .h_4;
  margin-bottom: 8px;
}

.paragraph {
  @include basicText;
  color: $hive-gray;
  max-height: 46px;
  min-height: 22px;
  overflow: hidden;
}

.resources {
  @include basicText;
  margin: 0 0 3px 0;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.updated {
  @include basicText;
  margin: 0;
  color: $hive-gray;
}

.createdBy {
  @include basicText;
  margin: 0 0 3px 0;
  color: $hive-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.createdByUser {
  @include basicText;
  color: $hive-primary-blue;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.createdByUser:dir(rtl) {
  flex-direction: row-reverse;
}

.privateChip {
  margin: 5px 0 0 5px;
}

.tagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tagWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 5px;
}

.linkedCollectionTag {
  margin-right: 0px;
}

.verifiedTag {
  margin-right: 0;
}

.curatedIcon {
  margin-left: 4px;
  margin-top: 4px;
  width: 15px;
  height: 15px;
}

// in user drawer
.inUserDrawer {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  .imageInner {
    width: 30%;
  }
  .mainImage {
    max-height: none;
    min-height: initial;
  }
  .textInner {
    width: 70%;
  }
  .textHeightInner {
    height: auto;
    min-height: initial;
  }
  .title {
    max-height: 42px;
    min-height: 42px;
    margin: 0 0 20px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    letter-spacing: -0.5px;
  }
}

.cardFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
}
