
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: 100%;
}

.column {
  width: 60%;
}

.infoColumn {
  margin-top: 60px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.tabTitle {
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 30px;
  margin-top: 40px;
  color: $hive-grey-black;
}

.infoContainer2 {
  width: 75%;
  margin-left: 7%;
  padding: 10px;
  background-color: rgba($hive-white, 0.4);
  border-radius: 12px;
}

.profileImageContainer {
  display: flex;
  margin: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid $hive-primary-blue;
}

.profileImageLabel {
  font-size: 16px;
  color: $hive-grey-black;
  font-weight: 600;
}

.profileImageButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 5px 15px;
}

.image {
  width: 80px;
  height: 80px;
  margin-left: auto;
  border-radius: 50%;
  background-size: cover;
  background-position: top center;
}

.image:dir(rtl) {
  margin-left: 0;
  margin-right: auto;
}
