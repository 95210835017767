
          @import "src/scss/styles.scss";
        
.cardsLoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 10px;
  position: relative;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 10px;
  height: 250px;
  width: auto;
  &.noBoxShadow {
    box-shadow: none;
  }
}
