
          @import "src/scss/styles.scss";
        
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}

.input {
  margin-top: 24px;
  margin-bottom: 48px;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 66px;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
}

.drawer {
  width: 35%;
  height: 100%;
  background-color: $hive-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px;
  word-wrap: break-word;
  overflow: auto;
}


.dividerColor {
  border-color: $hive-white-silver;
}