
          @import "src/scss/styles.scss";
        
.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.errorBox {
  min-height: 20px;
  padding-bottom: 5px;
}

.error {
  color: $hive-danger-red;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputWrapper {
  padding: 0;
}
