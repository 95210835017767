
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.buttonsAndLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  color: $hive-primary-blue;
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  gap: 5px;
}

.buttonsContainer:dir(rtl) {
  flex-direction: row-reverse;
}

.viewMore {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  cursor: pointer;
}

.arrowButton {
  margin: 0;
  font-size: 12px;
  padding: 5px;
  .arrowButtonImage {
    margin: 0;
    margin-right: 0;
  }
}
