
          @import "src/scss/styles.scss";
        
.buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  .button {
    img {
      padding-right: 0px;
      padding-left: 5px;
    }
  }
}
