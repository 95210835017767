
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: row;
  .mapContainer {
    background-color: $hive-medium-gray;
    width: 100%;
  }
}

.mapContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mapStyle {
  position: absolute;
  width: 100%;
  height: 100%;
}

.outsideWrap {
  position: fixed;
  bottom: 50px;
  width: 60%;
  margin: 0 10%;
}

.insideWrap {
  display: flex;
  justify-content: space-between;
}

.map {
  margin-top: -10%;
}

.detailsContainer {
  z-index: 1;
  padding: 0 12%;
  position: relative;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 17px;
}

.subheading {
  font-size: 16px;
  font-weight: 400;
}

.input {
  padding-top: 30px;
}

.btnwidth {
  width: 100px;
}

.buttons {
  display: flex;
  align-items: center;
}

.backButton {
  position: relative;
  margin-left: 2%;
  bottom: 33%;
}

.nextButton {
  position: relative;
  right: 20px;
  bottom: 33%;
  width: 100px;
}

.buttons {
  display: flex;
  flex: row;
  justify-content: space-between;
  bottom: 33%;
}
