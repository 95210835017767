
          @import "src/scss/styles.scss";
        
.searchInputContainer {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 2px solid transparent;
  background-color: rgba($hive-white, 0.5);
  padding-right: 20px;
  overflow: hidden;

  &:focus-within {
    border-color: $hive-primary-blue;
  }
}

.searchInput {
  background-color: rgba($hive-white, 0.5);
}

.resultsFound {
  margin-right: 14px;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $hive-gray;
}

.divider {
  margin-right: 15px;
  color: $hive-darker-gray;
}

.controls {
  display: flex;
  align-self: stretch;
}

.imgWrapper {
  display: flex;
  align-items: center;
  width: 15px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.arrowIcon {
  width: 100%;
}

.closeIcon {
  width: 100%;
}
