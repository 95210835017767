
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stepper {
  width: 184px;
  height: 24px;
}

.epicenterImage {
  margin-left: 30%;
  margin-top: 22%;
  margin-bottom: 111px;
  width: 83.3px;
  height: 62.88px;
}

.logo {
  width: 140px;
  height: 56px;
}

.step {
  margin-left: 50px;
}

.whoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 24px;
  margin-left: 50px;
  flex-grow: 1;
  text-align: center;
  width: min-content;
  span {
    color: rgba($hive-who-wrapper-span, 0.5);
    font-size: 11px;
    font-weight: 500;
  }
}