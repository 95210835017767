
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  justify-content: space-between;
}

.feedBack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  color: $hive-gray;
  font-size: 14px;
}
