
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.chat {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
  width: 95%;
  position: relative;
  margin-bottom: 20px;
  background-color: rgba($hive-white, 0.55);
  display: flex;
  flex-direction: row;
  background: rgba($hive-white, 0.55);
  box-shadow: 0px 3px 8px rgba($hive-grey-black, 0.1);
  border-radius: 37px;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

.chat:focus {
  outline: none !important;
  border: 0.01 solid rgba($hive-white, 0.55);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatButtonWrapper {
  margin-left: 5px;
  :global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    .arrowImg {
      margin-left: 5px;
    }
    &:disabled {
      background-color: transparent;
      &:hover {
        border: 1px solid $hive-disabled-color;
      }
    }
  }
}

.textAreaWrapper {
  display: block;
  position: relative;
}

.textArea {
  width: 100%;
  min-height: 10px;
  height: 37px;
  margin-top: 11px;
  border-radius: 0;
}
