
          @import "src/scss/styles.scss";
        
.disabledInputColor {
  background-color: $hive-disabled-background;
  color: $hive-disabled-color;
}

.profileImageContainer {
  display: flex;
  justify-content: space-between;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: top center;
}

.privacySettings {
  margin-top: 20px;
  border-top: 1px solid $hive-white-silver;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .title {
    font-size: 18px;
    color: $hive-grey-black;
  }

  .subTitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    color: $hive-gray;
  }
}
