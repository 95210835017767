
          @import "src/scss/styles.scss";
        
.recommendedForYouHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.recommendedForYouFirstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $hive-grey-black;
  margin-left: 10px;
}

.rightSectionSubtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: $hive-gray;
  padding-top: 8px;
  margin-left: 10px;
}

.communityLinkButtons {
  padding: 0;
  :global(.ant-btn-link) {
    padding: 0;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 14px;
}

.scrollableContainer {
  height: calc(100vh - 300px);
}

.scrollableDiv {
  height: 100%;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
  &:after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 12px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f2f6);
    pointer-events: none;
  }
}

.scrollableDiv::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.loadMoreButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 0 10px 0;
}
