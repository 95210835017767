
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  color: $hive-gray;
}

.hashtag {
  font-size: 18px;
  font-weight: 500;
  font-family: cursive;
}

.input {
  background-color: transparent;
  border: none;
  height: 24px;
  left: 28px;
  top: 0px;
  margin-left: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.closeIcon {
  padding: 0 10px;
  cursor: pointer;
}
