
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.row {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: 100%;
}

.column {
  width: 60%;
}

.infoColumn {
  margin-top: 60px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.tabTitle {
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 30px;
  margin-top: 40px;
  color: $hive-grey-black;
}

.infoContainer {
  width: 75%;
  margin-left: 7%;
  margin-bottom: 5%;
  padding: 10px;
  background-color: rgba($hive-white, 0.4);
  border-radius: 12px;
}

.infoContainer2 {
  width: 75%;
  margin-left: 7%;
  padding: 10px;
  background-color: rgba($hive-white, 0.4);
  border-radius: 12px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: $hive-grey-black;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.subtitle {
  color: $hive-gray;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-right: 10px;
  line-height: 24px;
}
