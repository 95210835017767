
          @import "src/scss/styles.scss";
        
.pill {
  border-radius: 100px;
  background-color: $hive-medium-gray;
  color: $hive-black;
  padding: 6px 12px;
  margin: 3px;
  transition: ease 100ms;
  font-size: 14px;

  &.disabled {
    background-color: $hive-medium-gray;
    cursor: auto;
  }

  &.close {
    cursor: pointer;
    gap: 10px;
    padding: 6px 2px 6px 12px;
  }
}

.alternativeColor {
  border: 1px solid $hive-white-silver;
}

.selected {
  background-color: $hive-white-silver;
}

.withCloseButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  .closeButtonWrapper {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    :global(.ant-btn) {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: fit-content;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      color: $hive-gray;

      &:hover {
        color: $hive-primary-blue;
        background-color: transparent;
      }
    }
  }

  .closeIcon {
    color: $hive-grey-select;

    &:hover {
      color: $hive-primary-blue;
    }
  }

  &:hover {
    cursor: default;
  }
}
