
          @import "src/scss/styles.scss";
        
.options {
  background: $hive-white;
  border: 1px solid $hive-disabled-background;
  border-radius: 4px;
  display: flex;
  box-shadow: 0px 3px 8px rgba($hive-black, 0.05);
  overflow: hidden;
}

.option {
  padding: 0;
  cursor: pointer;

  :global(.ant-btn) {
    padding: 6px 4px;
    height: auto;
    border: none;
    border-radius: 0;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
