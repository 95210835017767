
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles";

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.wrapper {
  overflow-y: auto;
}

.switchWrapper {
  height: 28px;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $hive-light-gray;
  overflow-y: auto;
  padding: 30px;
}

.pageTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.newEventForm {
  width: 100%;
  max-width: 583px;
  padding: 10px;
}

.selectWrapper {
  position: relative;
  margin-top: 24px;
}

.imagePickerForNewEvent {
  margin-top: 9px;
  margin-bottom: -15px;
}

.searchImage {
  position: absolute;
  transform: translateY(-50%);
  top: 75%;
  left: 15px;
  z-index: 2;
  pointer-events: none;
}

.radioTypeWrapper {
  padding: 15px 0;
  .buttonWrapper {
    display: flex;
  }
  .meetingWrapper {
    padding: 5px 35px 10px;
  }
}

.radioTypeHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 9px;
}

.radioTypeHeading:dir(rtl) {
  text-align: right;
}

.inlineInputButton {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
  display: flex;
  align-items: center;
}

.buttons {
  display: grid;
  flex-direction: column;
  align-items: center;
}

.cancelLink {
  display: flex;
  margin: auto;
  color: $hive-primary-blue;
  cursor: pointer;
}

.descriptionContainer {
  border: 1px solid $hive-black;
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 4px 10px;
  border-radius: 100px;
}

.inputContainer {
  position: relative;
  margin-top: -9px;
  input {
    padding-right: 140px;
  }
}

.attachmentButton {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $hive-primary-blue;
  border: 1px solid $hive-primary-blue;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  width: auto;
}

.dataIcon {
  margin-right: 5px;
}

.attachFile {
  > button {
    display: flex;
    align-items: center;
  }
}

.startDateContainer {
  display: flex;
  flex-direction: column;
}

.startDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $hive-white;
  border-radius: 30px;
  width: 25%;
  padding: 10px 10px;
}

.dateIcon {
  margin-left: 10px;
}

.dateValue {
  font-size: 12px;
  font-weight: 400;
  padding-left: 5px;
}

.datesContainer {
  padding-top: 20px;
}

.textTop {
  margin-bottom: 5px;
}

.rowFirstContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blueArrow {
  margin-left: 15px;
  margin-right: 15px;
  height: 18px;
}

.blueArrow:dir(rtl) {
  transform: rotate(180deg);
}

.startCalender {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.firstCalender {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $hive-white;
  border-radius: 30px;
  font-size: 12px;
  cursor: pointer;
  width: 160px;
}

.dateValue {
  padding-left: 2px;
  font-size: 14px;
}

.dateIcon {
  width: 15px;
  height: 15px;
}

.txtContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.txtContainer:dir(rtl) {
  justify-content: start;
  gap: 45px;
}

.formGroup {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.datePicker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.endContainer {
  margin-right: 39%;
}

.arrow {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  padding-top: 5px;
  width: 10px;
  height: 20px;
}

.secondCalender {
  @extend .firstCalender;
  margin-left: 10px;
}

.secondCalenderError {
  @extend .firstCalender;
  margin-left: 10px;
  border: 1px solid $hive-danger-red;
}

.picker {
  z-index: 5;
  > div {
    display: none;
  }
  > span > div > div {
    border: none;
    border-radius: 10px;
  }
}

.errorPicker2 {
  z-index: 5;
  border: 1px solid $hive-danger-red;
  height: 100%;
  > div {
    display: none;
  }
  > span > div > div {
    border: 1px solid $hive-danger-red;
    border-radius: 10px;
  }
}

.datePicker {
  height: 100%;
}

.picker2 {
  @extend .picker;
  margin-left: 45%;
}

.timePicker {
  background-color: $hive-white;
  border-radius: 30px !important;
  height: 100%;
  width: 160px;
  margin-left: 5px !important;
  font-size: 14px;
  border: none !important;
  :global(.react-time-picker__inputGroup__input) {
    height: auto;
  }
  > div > div > input:nth-child(2) {
    width: 18px !important;
  }

  > div {
    border: none;
  }
  > div > button {
    display: none;
  }
  > span > div {
    display: none;
  }
  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.timePickerError {
  background-color: $hive-white;
  border-radius: 30px !important;
  width: 160px;
  height: 100%;
  margin-left: 5px !important;
  font-size: 14px;
  border: 1px solid $hive-danger-red !important;
  > div > div > input:nth-child(2) {
    width: 18px !important;
  }

  > div {
    // border: 1px solid $hive-danger-red;
    border-radius: 30px;
  }
  > div > button {
    display: none;
  }
  > span > div {
    display: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-left: 5px;
}

.fileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fileName {
  font-size: 16px;
  font-weight: 400;
  max-width: 420px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.size {
  color: $hive-grey-name;
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.sizeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconCancel {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.paperClip {
  display: flex !important;
  font-size: 20px;
  transform: rotate(180deg);
}

.externalUrlNote {
  font-size: 14px;
  font-weight: 400;
  color: $hive-grey-name;
}

.communitiesHeader {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}

.selectedCommunityRow {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.communityActions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.removeCommunity {
  cursor: pointer;
}
