
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";

.phoneNumber {
  .inputLabel {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 16px;
    color: $hive-black;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
}

.textBottom {
  color: $hive-gray;
  font-size: 14px;
  white-space: nowrap;
  margin-top: 3px;
}

.timeZones {
  width: 100%;
  label {
    margin-bottom: 0;
  }
  font-size: 14px;
}

.categoriesSearch {
  border-radius: 100px;
  font-size: 13px;
  width: 400px;

  > div > div {
    background-color: rgba($hive-white, 0.6);
    padding-left: 27px;
    > div {
      svg {
        background-color: $hive-grey-select;
        border-radius: 100px;
        padding: 2px;
        color: $hive-white;
      }
    }
  }
}

.tagsSearch {
  border-radius: 100px;
  font-size: 16px;
  border: 2px solid transparent;
  margin-top: 10px;
  label {
    font-weight: 500px;
    div {
      margin-bottom: 8px;
    }
  }

  > div > div {
    padding-right: 10px;

    > div {
      svg {
        background-color: $hive-grey-select;
        border-radius: 100px;
        padding: 2px;
        color: $hive-white;
        transition: 100ms ease;
        &:hover {
          background-color: $hive-primary-blue;
        }
      }
    }
  }
}

.tagsSearchUpload {
  @extend .tagsSearch;

  > div > div:nth-child(3) {
    max-height: 102px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
}

.dropdown {
  height: 5%;
  padding: 10px 0;
}

.inputLabel {
  @extend .h_6;
  color: $hive-black;
  flex-direction: column;
}

.boldLabel {
  @extend .inputLabel;
  @extend .globalBoldLabel;
}

.chatSelect {
  width: 150px;
}

.selectRole {
  width: 120px;
}

.selectRoleDisabled {
  @extend .selectRole;
  cursor: not-allowed;
}

.list:dir(rtl) {
  text-align: right !important;
}

.timeZone {
  width: 100px;
  height: 100px;
}

.customOption {
  transition: background 60ms;
  background-color: transparent !important;
  cursor: pointer;
  color: initial !important;
  padding-left: 10px !important;
  &:hover {
    background-color: $hive-primary-blue !important;
    color: $hive-white !important;
  }
}

.customOption:hover {
  transition-delay: 60ms;
}

.customOption.custom-select__option--is-focused {
  background: none;
}

.customOption.custom-select__option--is-selected {
  background: $hive-select-blue;
}

.label {
  margin-bottom: 10px;
}

.select {
  .singleValue {
    z-index: 0;
  }

  img:dir(rtl) {
    margin-right: 8px;
  }

  input[type="text"]:focus {
    outline: none !important;
  }
}
