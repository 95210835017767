
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
}

.wrapper {
  width: 100%;
  padding-right: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.interestsHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headingContainer {
  margin: 25px 20px 20px 0px;
}

.heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: $hive-grey-black;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.interestHeading {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: $hive-grey-black;
}

.noInterestsHeading {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $hive-grey-black;
  margin-top: 30px;
}

.interestsHeadingButton {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $hive-primary-blue;
}

.interest {
  cursor: pointer;
  border-radius: 30px;
  background-color: rgba($hive-white, 0.55);
  padding: 10px 20px;
  margin: 2px;
  border: none;
  font-weight: 400;
  color: $hive-grey-black;
  border: 1px solid $hive-white;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interestSelect {
  cursor: pointer;
  border-radius: 30px;
  background-color: $hive-primary-blue;
  color: $hive-white;
  padding: 10px 20px;
  margin: 2px;
  border: none;
  font-weight: 400;
  border: 1px solid $hive-white;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInterests {
  border-radius: 100px;
  border: 2px solid transparent;
  background-color: rgba($hive-white, 0.55);
  height: 48px;
  padding-left: 50px;
}

.buttonName {
  font-size: 14px;
  font-weight: 500;
  width: 10%;
  height: 40px;
  margin-top: 10px;
}

.icon {
  width: 20px;
  height: 20px;
  padding-left: 5px;
}
.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 40px;
}
