
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.pageWrapper {
  flex-grow: 1;
  overflow-y: auto;
  padding: 35px 20px 20px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  text-align: left;
  color: $hive-grey-black;
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 40px;
  margin: 0 auto 20px auto;
  background-color: $hive-white;
}

.notification {
  &:not(:last-child) {
    border-bottom: 1px solid $hive-disabled-background;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.noNewNotifiactions {
  margin-bottom: 0;
  text-align: center;
}

.loadMoreButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 0 10px 0;
}