
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles";
.pageWrapper {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 60px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  text-align: left;
  color: $hive-grey-black;
}

.title:dir(rtl) {
  text-align: right;
}

.sectionWrapper {
  margin-bottom: 10px;
}

.copyLinkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $hive-white;
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 2px 10px;
}

.copyLink {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonTokenWrapper .buttonWrapper {
  padding-top: 0;
}
