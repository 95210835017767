
          @import "src/scss/styles.scss";
        
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputWrapper {
  background-color: $hive-white;
  border: 2px solid transparent;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.sm {
    padding: 6px 10px;
  }

  &.md {
    padding: 10px;
  }

  &.lg {
    padding: 15px;
  }

  .icon {
    display: block;
    padding-inline-end: 5px;
  }

  &.border {
    border: 2px solid $hive-light-gray;

    &:focus-within {
      border: 2px solid $hive-primary-blue;
    }
  }

  &.rounded {
    border-radius: 100px;
  }

  &:disabled {
    background-color: $hive-disabled-background;
    outline: none;
    pointer-events: none;
  }

  &.loading {
    background-color: $hive-disabled-background;
    outline: none;
    pointer-events: none;

    .loaderContainer {
      visibility: visible;
    }
  }

  .input {
    flex-basis: 100%;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.loaderContainer {
  visibility: hidden;

  > div {
    display: flex;
  }

  > div > div {
    display: flex;
  }
}
