
          @import "src/scss/styles.scss";
        
.communityCollectionCard {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  cursor: pointer;
}

.communityCollectionCardImage {
  width: 83px;
  min-width: 83px;
  height: 83px;
  background-size: cover;
  background-position: top center;
  border-radius: 8px 0 0 8px;
}

.communityCollectionCardImage:dir(rtl) {
  border-radius: 0 8px 8px 0;
}

.communityCreatorImage {
  width: 32px;
  height: 32px;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}

.communityCollectionCardText {
  overflow: hidden;
  padding: 16px;
  height: 83px;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  width: 100%;
}

.communityCollectionCardText:dir(rtl) {
  border-radius: 8px 0 0 8px;
}

.communityCollectionCardName {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.5px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.communityCollectionCardValues {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.communityTypeAndRole {
  display: flex;
  gap: 5px;
}

.communityCollectionCardCollections {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.communityCollectionCardRole {
  display: flex;
}

.communityCollectionCardCollectionsFont {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #697377;
  padding-left: 8px;
  flex-shrink: 0;
}

.communityImageWrapper {
  background-color: #fff;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  padding: 8px;
}

.communityImageWrapper:dir(rtl) {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.communityImage {
  height: 67px;
  width: 67px;
  min-width: 67px;
  background-size: cover;
  background-position: top center;
  background-color: $hive-white;
  border-radius: 50%;
}

.communityRole {
  display: flex;
  justify-content: flex-end;
}

.communityType {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dde3e6;
  border-radius: 100%;
}
