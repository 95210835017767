
          @import "src/scss/styles.scss";
        
.channel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  gap: 10px;
  color: $hive-gray;
  cursor: pointer;

  &.active {
    color: $hive-new-blue;
  }

  &.disabled {
    pointer-events: none;
  }

  &.reccentDiscussion {
    color: $hive-primary-blue;
  }
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hashtag {
  font-size: 18px;
  font-weight: 500;
  font-family: cursive;
}
