
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.topContainer {
  padding: 0 30px 30px 30px;
  background-color: rgba($hive-white, 0.4);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
}

.img {
  margin-top: 80px;
}

.loadingContainer {
  padding-top: 20%;
}

.eventCardsContainer {
  width: 36%;
  margin-left: 20px;
}

.eventCard {
  min-height: 90px;
  background-color: $hive-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  padding: 10px;
  margin-bottom: 20px;
}

.recording {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardTitle {
  font-weight: 700;
}

.eventRecordingPassword {
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.categoriesContainer {
  padding: 20px 0;
}
