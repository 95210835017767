
          @import "src/scss/styles.scss";
        
@import "../../scss//styles.scss";

.container {
  width: 100%;
  padding: 32px;
  padding-top: 18px;
  overflow-y: auto;
}
