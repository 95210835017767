
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles";
.textBottom {
  color: $hive-gray;
  font-size: 14px;
  white-space: nowrap;
  margin-top: 3px;
}

.inputLabel {
  @extend .h_6;
  color: $hive-black;
  flex-direction: column;
}

.boldLabel {
  @extend .inputLabel;
  @extend .globalBoldLabel;
}

:global(.ant-select.ant-select-focused .ant-select-selector) {
  box-shadow: 0 0 0 1px $hive-primary-blue !important;
} 

.select {
  width: 100%;
  margin-top: 10px;
  :global(.ant-select-selector) {
    padding: 10px;
    border-radius: 25px !important;
    border: none !important;
  }  

  :global(.ant-select-selection-placeholder) {
    color: $hive-info-text;
  }

  input[type="text"]:focus {
    outline: none !important;
  }
}

.errorMessage {
  position: relative;
  top: 5px;
  color: $hive-danger-red;
}
