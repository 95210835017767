
          @import "src/scss/styles.scss";
        
.modal {
  width: 601px;
  height: 611px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 32px 0;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  letter-spacing: -0.5px;
  color: #21272a;
}

.tabsWrapper {
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      &::before {
        border-bottom: none;
      }
    }

    :global(.ant-tabs-nav-list) {
      :global(.ant-tabs-tab) {
        padding: 8px 0;
      }

      :global(.ant-tabs-tab) :global(.ant-tabs-tab-btn) {
        color: $hive-grey-name;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }

      :global(.ant-tabs-tab.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
        color: $hive-grey-black;
      }

      :global(.ant-tabs-ink-bar) {
        background: $hive-primary-blue;
      }
    }
  }
}

.tabsContentWrapper {
  margin-top: 20px;
  flex-grow: 1;
  overflow: auto;

  span:global(.collections-tree-node-title) {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
    vertical-align: middle;
  }

  span:global(.collections-tree-node-title.empty) {
    font-size: 12px;
    line-height: 17px;
    color: $hive-gray;
  }

  span:global(.collections-tree-node-title.new-folder) {
    input {
      border: none;
      padding: 0px 8px;
      border-radius: 4px;
      height: 26px;
      outline: none;
    }
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

.newCollection {
  margin-right: auto;
}

.cancelLink {
  margin: 0 10px 0 0;
}
