
          @import "src/scss/styles.scss";
        
.text {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: $hive-gray;
  display: flex;
  flex-direction: row;
}

.dotMargin {
  margin: 0 2px;
}
