
          @import "src/scss/styles.scss";
        
.option {
  padding: 15px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $hive-medium-gray;
  }
}
