
          @import "src/scss/styles.scss";
        
.container {
  height: 266px;
  width: 550px;
  background-color: $hive-white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.htmlCardWrapper {
  position: absolute;
  z-index: -1;
}

.rowSecond {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.img {
  margin-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnSecond {
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.titleWrapper {
  display: flex;
  gap: 10px;
}

.title {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $hive-grey-black;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttonCTA {
  padding: 0;
}

.timeTitle {
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: $hive-grey-black;
}

.time {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.description {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  text-align: left;
  color: $hive-gray;
}

.border {
  border: 1px solid $hive-white-silver;
}

.button {
  flex-basis: 100%;
}

.eventType {
  @extend .description;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}
