
          @import "src/scss/styles.scss";
        
.Wrapper {
  display: flex;
  flex-direction: column;
}

.Item {
  display: flex;
  padding: 9.6px 13.6px;
  align-items: center;
  color: $hive-grey-black;
  border-bottom: 1px solid $hive-disabled-background;

  .Image {
    width: 28.8px;
    height: 28.8px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }

  .Title {
    margin-left: 9.6px;
    width: calc(100% - 63px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: auto;
    cursor: pointer;

    &:dir(rtl) {
      transform: rotate(180deg);
    }
  }
}
