
          @import "src/scss/styles.scss";
        
.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.peopleList {
  max-height: 280px;
  overflow-y: auto;
  margin: 20px 0 0 0;
}

.personItem {
  margin: 0 0 24px 0;
  display: flex;
  cursor: pointer;
}

.personTextContainer:dir(rtl) {
  margin-right: 8px;
  text-align: right;
}

.avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
}

.title {
  @extend .h_7;
  margin: 0 0 4px 0;
  display: flex;
}

.modalSubtitle {
  display: flex;
  margin-top: 8px;
}

.subTitle {
  @extend .h_8;
  text-align: left;
}

.subTitle:dir(rtl) {
  text-align: right;
}

.dotMargin {
  margin: 0 2px;
}
