
          @import "src/scss/styles.scss";
        
.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: $hive-light-gray;
  overflow-y: auto;
  margin-top: 84px;
}

.pageTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.newCommunityForm {
  width: 100%;
  max-width: 583px;
  padding: 10px;
}

.inputContainer {
  position: relative;
  input {
    padding-right: 140px;
  }
}

.inlineInputWrapper {
  position: absolute;
  right: 10px;
  bottom: 11px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  width: 100%;
  padding: 5px 0;
}

.cancelLink {
  display: flex;
  margin: auto;
  color: $hive-primary-blue;
  cursor: pointer;
}

.member {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.userDetails {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.usersContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 20px;
  border: 1px solid $hive-disabled-background;
  scrollbar-width: none; /* Firefox */
}

.usersContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: $hive-white;
  padding: 6px 10px 6px 15px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    background-color: $hive-light-gray;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 10px;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.role {
  color: $hive-gray;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.name {
  margin-bottom: 0px;
  padding-left: 0px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  img {
    margin-left: 12px;
    cursor: pointer;
  }
}

.communityTypeWrapper {
  padding: 15px 0;
}

.communityTypeHeading {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 15px;
}

.addCollaboratorsLabel {
  font-size: 16px;
  font-weight: 700;
}

.selectWrapper {
  position: relative;
}
