
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.step {
  cursor: pointer;
  svg {
    border: 1px solid $hive-stepper-grey;
    border-radius: 100%;
    color: $hive-white;
    margin-bottom: 7px;
    path {
      color: $hive-primary-blue;
    }

    circle {
      display: none;
    }
    text {
      display: none;
    }
  }
}

.root {
  width: 100%;
  color: $hive-white;
  .wrapper {
    background-color: transparent;
  }
}

.root:dir(rtl) {
  .wrapper {
    align-items: flex-start;
    :global(.MuiStepConnector-root) {
      margin-right: 18.5px;
    }
  }
}

.stepActive {
  svg {
    border: 1px solid $hive-primary-blue;
    border-radius: 100%;
    color: $hive-white;
    margin-bottom: 7px;
    circle {
      display: none;
    }
    text {
      display: none;
    }
  }
}

.stepActive:dir(rtl) {
  svg {
    margin-left: 8px;
  }
}

.step:dir(rtl) {
  svg {
    margin-left: 8px;
  }
}

.completed {
  svg {
    border: $hive-pure-red 5px solid;
  }
}

.stepWrapper {
  cursor: pointer;
}
