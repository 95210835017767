
          @import "src/scss/styles.scss";
        
@import "./../../../scss/palette";

.attachmentBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-left: 25px;
  overflow-y: auto;
  max-height: 40vh;
  padding-right: 5px;
  margin-top: 24px;
}

.fileContainer {
  display: flex;
  background-color: $hive-white;
  align-items: center;
  border-radius: 8px;
  padding: 0px 8px;
  justify-content: space-between;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-top: 50px;
  margin-left: 50px;
}
