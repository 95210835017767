
          @import "src/scss/styles.scss";
        
.collectionsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
}

.collectionsHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.collectionsHeadingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collectionsIcon {
  width: 30px;
  margin-right: 8px;
}

.collectionsTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1.5px;
  text-align: left;
  margin: 0;
}

.collectionsButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.viewMoreButton:hover {
  border: 1px solid transparent;
}

.roundedButton {
  width: 14px;
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin: 0;
  margin-left: 5px;
  border-radius: 50%;
  &:disabled {
    border: 1px solid $hive-disabled-color;
  }
}

.collectionsContent {
  display: flex;
  flex-direction: row;
}

.loaderWrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noSearchContainer {
  padding-top: 100px;
}

.noSearchText {
  font-weight: 700;
  font-size: 36px;
}
.smallNumberOfCardsContainer {
  display: flex;
  flex-direction: row;
}
.textContainer {
  @extend .h_4;
  margin-top: 24px;
}

.customStyles {
  margin: 10px;
}