
          @import "src/scss/styles.scss";
        
.loader {
  display: flex;
  margin-left: 45%;
  margin-bottom: 10%;
}

.loaderAutoMargin {
  display: flex;
  margin: auto;
}
