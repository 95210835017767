
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.wrapper {
  padding: 15px 0;
}

.heading {
  @extend .h_6;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
}

.heading:dir(rtl) {
  text-align: right;
}

.imagePreviewWrapper {
  display: inline-block;
  position: relative;
}

.imagePreview {
  max-width: 100%;
  background-color: $hive-dirty-white;
  opacity: 0.6;
}

.removeIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.radioGroup {
  width: fit-content;
  img {
    border-radius: 10px;
  }
}

.imageRequiredDescription {
  color: $hive-grey-name;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

// modal
.modal {
  max-width: 601px;
  padding: 32px 40px 22px 40px;
  .title {
    margin-bottom: 32px;
  }
}

.gridWithFour {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(4, 1fr);
}

.gridWithThree {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(3, 1fr);
}

.radioGroup {
  margin: 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
