
          @import "src/scss/styles.scss";
        
.removeIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.fileName {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: medium;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.cancelButton {
  font-size: 16px;
  color: $hive-primary-blue;
  font-weight: 400;
  cursor: pointer;
}
