
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles.scss";

.eventContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.eventCard {
  flex-basis: 25%;
  min-width: 0;
}

.searchedEvents {
  @extend .eventsContainer;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.headingContainer {
  padding: 0 12px;
}

.heading {
  display: flex;
  @extend .h_2;
  margin: 0 0 64px 0;
}

.title {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4.5px;
}

.caption {
  padding-left: 5px;
}

.noResultsContainer {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgWrapper {
  margin-bottom: 60px;
}

.img {
  width: 100%;
}

.noResultsText {
  @extend .h_2;
  color: $hive-grey-black;
  margin-bottom: 20px;
}

.infoText {
  @extend .h_5;
  color: $hive-gray;
}

.girdOuter {
  margin: 12px 0 0 0;
}

.resultsTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 64px 0;
  .header {
    @extend .h_2;
  }
  .subHeader {
    @extend .h_5;
    font-weight: 500;
    margin: 0 0 5px 0;
  }
  
}