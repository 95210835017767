
          @import "src/scss/styles.scss";
        
.communityMessage {
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 20px;
}

.communityMessage.ownMessage {
  flex-direction: row-reverse;
}

.avatar {
  border-radius: 100%;
  background-size: cover;
  background-position: top center;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &.greetingMessage {
    cursor: unset;
  }
}

.messageContainerWrapper {
  position: relative;
}

.messageContainer {
  background-color: $hive-white;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.communityMessage.ownMessage .messageContainer {
  background-color: $hive-message-background;
}

.userName {
  font-weight: bold;
  cursor: pointer;

  &.greetingMessage {
    cursor: unset;
  }
}

.messageInfoContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.message {
  padding-top: 12px;
  word-break: break-word;
  min-width: 500px;
  max-width: 700px;
}

.startMessage {
  @extend .message;
  white-space: nowrap;
}

.description {
  font-size: 12px;
  color: $hive-gray;
}

.discussionName {
  margin-bottom: 8px;
  color: $hive-primary-blue;
  cursor: pointer;
}

.time {
  color: $hive-gray;
  font-size: 14px;
}

.fileContainer {
  max-width: 50%;
}

.reactions {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  z-index: 2;
}

.reactions:dir(rtl) {
  left: auto;
  right: 0;
}

.ownMessage {
  .reactions {
    left: auto;
    right: 0;
  }

  .reactions:dir(rtl) {
    left: 0;
    right: auto;
  }
}
