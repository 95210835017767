
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.communityReplyMessage {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  transition: 0.3s;
}

.messageDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user {
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  word-break: break-word;
  min-width: 500px;
  max-width: 700px;
  margin-top: 12px;
}

.startMessage {
  @extend .message;
  white-space: nowrap;
}

.discussionName {
  margin-bottom: 8px;
  color: $hive-primary-blue;
  cursor: pointer;
}

.rightSide {
  padding: 16px;
  border-radius: 4px;
  background-color: #EBF0FF;
}

.communityReplyMessage.ownMessage .rightSide {
  background-color: $hive-white;
}

.communityReplyMessage.ownMessage.beforeInput .rightSide {
  background-color: #EBF0FF;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  border-left: 3px solid $hive-primary-blue;
}

.time {
  color: $hive-gray;
  margin-left: 5px;
  font-size: 14px;
}

.imgWrapper {
  width: 40px;
}

.img {
  cursor: pointer;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: top center;
}

.fileContainer {
  max-width: 50%;
}

.downloadIcon {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.fileName {
  font-weight: 400;
  font-size: 12px;
  color: $hive-grey-black;
}

.fileSize {
  color: $hive-grey-name;
  font-size: 10px;
}

.fileNameContainer {
  display: flex;
  flex-direction: column;
}

.description {
  font-size: 12px;
  color: $hive-gray;
}

.removeIcon {
  cursor: pointer;
}
