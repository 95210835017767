
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.linksWrapper {
  padding-bottom: 15px;
}

.linksHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @extend .h_6;
  letter-spacing: 0px;
  text-align: left;
  color: $hive-black;
}

.title:dir(rtl) {
  text-align: right;
  margin-right: 0;
}

.linksRow {
  display: flex;
  position: relative;
  margin-top: 10px;
  &:first-of-type {
    margin-top: 20px;
    .buttonUp {
      display: none;
    }
  }
  &:last-of-type {
    margin-top: 20px;
    .buttonDown {
      display: none;
    }
  }
}

.linksInputsWrapper {
  width: 100%;
  .inputOuterWrapper {
    width: 100%;
    margin-top: -20px;
  }
  .inputOuterWrapper:first-of-type {
    max-width: 300px;
  }
}

.actions {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.actions:dir(rtl) {
  left: 0;
  right: auto;
}

.actionButton {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: transparent;
  color: $hive-primary-blue;
  transition: 100ms ease;
  border-radius: 50%;
  border: 1px solid $hive-primary-blue;
  box-shadow: 0 2px 0 rgba($hive-black, 2%);
  cursor: pointer;
  &:hover {
    background-color: $hive-medium-gray;
    color: $hive-primary-blue;
    border-color: $hive-primary-blue;
    cursor: pointer;
  }
  img {
    display: block;
    max-height: 20px;
  }
}

.buttonUp {
  transform: rotate(-90deg);
}

.buttonUp:dir(rtl) span svg {
  translate: 4px 1px;
  transform: rotate(180deg);
}

.buttonDown {
  transform: rotate(90deg);
}

.buttonDown:dir(rtl) span svg {
  translate: 3px 2px;
  transform: rotate(180deg);
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.errorMessage {
  color: $hive-danger-red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
  margin-left: 5px;
}
