
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.checkboxWrapper {
  padding: 10px;
}
.checkboxLabel {
  display: flex;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  &:hover input ~ .checkmarkIcon {
    background: $hive-disabled-color;
  }
  .checkboxInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmarkIcon {
      background-color: $hive-primary-blue;
    }
    &:checked ~ .checkmarkIcon:after {
      display: block;
      margin-left: 1px;
    }
  }
  .checkmarkIcon {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background: $hive-default-grey;
    border-radius: 100px;
		border: 1px solid $hive-primary-blue;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid $hive-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
