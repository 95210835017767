
          @import "src/scss/styles.scss";
        
.container {
  overflow-y: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.tabContainer {
  padding: 32px 32px 40px 32px;
}

.breadCrumbsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #e6ecef;
  padding: 16px 32px 22px 32px;
  gap: 12px;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e6ecef;
  padding: 0 32px;
}

.titleContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    position: static;
    margin-left: 12px;
  }
}

.manageButton {
  display: flex;
  align-items: center;
  color: $hive-primary-blue;
  gap: 8px;

  letter-spacing: -0.5px;
  font-feature-settings: "tnum" on, "lnum" on;
}

.middleBar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #e6ecef;
  padding: 16px 32px 0 32px;
}

.bottomBar {
  background-color: #e6ecef;
  padding: 16px 32px 12px 32px;
  p {
    @extend .h_7;
    color: $hive-gray;
  }
}

.sortContainer {
  padding: 16px 32px 20px 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.sortDropdownWrap {
  min-width: 200px;
  margin: 0 5px 0 0;
}

.title {
  @extend .h_3;
  margin: 0;
}

.actionWraper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.actionWraper:dir(rtl) {
  margin-left: 0;
}

.actionButton {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background-color: transparent;
  color: $hive-primary-blue;
  transition: 100ms ease;
  border-radius: 20px;
  border: 1px solid rgba($hive-primary-blue, 0.2);
  box-shadow: 0 2px 0 rgba($hive-black, 2%);
  cursor: pointer;
  &:hover {
    background-color: $hive-medium-gray;
    color: $hive-primary-blue;
    border-color: $hive-primary-blue;
    cursor: pointer;
  }

  svg {
    path {
      fill: $hive-primary-blue;
    }
  }

  &.actionText {
    padding: 0 20px;

    span {
      margin-left: 12px;
    }
  }
}

.interestsItem {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  padding: 2px 5px;
  border: 1px solid $hive-white-silver;
  border-radius: 20px;
  margin: 0 4px 0 0;
  display: inline-block;
}

.peopleOuter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  cursor: pointer;
  min-width: 200px;
  color: $hive-primary-blue;
}

.peopleInner {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 24px 0 0;
  margin: 0 4px 0 0;
}

.smallAvatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid $hive-white;

  ~ img {
    position: absolute;
    left: 12px;
    ~ img {
      position: absolute;
      left: 24px;
    }
  }
}

.text {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: $hive-gray;
}

.dotMargin {
  margin: 0 2px;
}

.buttonMargin {
  margin: 0 0 0 24px;
}
.type {
  @extend .text;
  padding-right: 5px;
}

.uploadIcon {
  max-height: 16px;
  margin-right: 8px;
}

.uploadIcon:dir(rtl) {
  margin-right: 0;
  margin-left: 8px;
}

.inputFile {
  display: none;
}

.toaster {
  width: 400px;
  margin-left: -80px;
  cursor: default !important;
  display: none !important;

  &.active {
    display: flex !important;
  }
}

.toaster:dir(rtl) {
  transform: translateX(80px);
}

.fileUploadingName {
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upperSection:dir(rtl) {
  flex-direction: row-reverse;
  text-align: right;
}

.uploadingFileName {
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 6px;
  color: rgba($hive-black, 90%);
}

.fileIcon {
  max-height: 40px;
}

.infoSection {
  width: 250px;
}

.cancelButton {
  color: $hive-primary-blue;
  cursor: pointer;
}

.categoriesWrapper {
  display: flex;
  align-items: center;
}

.verifiedTag {
  margin-right: 10px;
}

.verifiedTag:dir(rtl) {
  margin-right: 0;
  margin-left: 10px;
}

.progress:dir(rtl) {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

:global(.ant-progress-inner):dir(rtl) {
  display: flex;
  justify-content: flex-end;
}
