
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.settingsContainer {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  :global(.ant-tabs){
    flex-grow: 1;
    :global(.ant-tabs-content) {
      height: 100%;
      :global(.ant-tabs-tabpane) {
        height: 100%;
      }
    }
  }
}

.submenu {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid $hive-light-blue;
}

.activeItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  font-size: 18px;
  color: $hive-grey-black;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 2px solid $hive-grey-black;
}

.border {
  border-bottom: 2px solid $hive-grey-black;
}

.menuItem {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 18px;
  color: $hive-gray;
  font-weight: 400;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $hive-grey-black;
}