
          @import "src/scss/styles.scss";
        
.container {
  background-color: $hive-medium-gray;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
