
          @import "src/scss/styles.scss";
        
.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: $hive-light-gray;
  overflow-y: auto;
  padding: 20px;
}

.pageTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.inviteLabel {
  font-weight: 700;
  font-size: 16px;
}

.newCommunityForm {
  width: 100%;
  max-width: 583px;
  padding: 10px;
}

.selectWrapper {
  position: relative;
}

.communityTypeWrapper {
  padding: 15px 0;
}

.communityTypeHeading {
  @extend .h_6;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  color: $hive-black;
}

.communityTypeHeading:dir(rtl) {
  text-align: right;
}

.inlineInputButton {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
}

.member {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.userDetails {
  display: flex;
  align-items: center;
  gap: 8px;
}

.role {
  color: $hive-gray;
  font-size: 13px;
  margin-bottom: 0px;
}

.name {
  margin-bottom: 0px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 12px;

  img {
    cursor: pointer;
  }
}

.actionsContainer:dir(rtl) {
  input {
    width: 0;
  }
}

.buttonWrapper {
  width: 100%;
  padding: 5px 0;
}

.cancelLink {
  display: flex;
  margin: auto;
  color: $hive-primary-blue;
  cursor: pointer;
}

.descriptionContainer {
  border: 1px solid $hive-black;
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 4px 10px;
  border-radius: 100px;
}

.inputContainer {
  position: relative;
  input {
    padding-right: 140px;
  }
}

.communityTypeIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  img {
    width: 14px;
    height: 14px;
  }
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: $hive-white;
  padding: 6px 10px 6px 15px;
  gap: 10px;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    background-color: $hive-light-gray;
  }
}

.usersContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 20px;
  border: 1px solid $hive-disabled-background;
  scrollbar-width: none; /* Firefox */
}

.usersContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  transition: 200ms ease;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}
