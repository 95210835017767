
          @import "src/scss/styles.scss";
        
.eventCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
  height: 100%;
}

.hostedBy {
  text-wrap: wrap;
}

.container {
  position: relative;
  background-color: $hive-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }
}

.textBottom {
  display: flex;
  flex-direction: column;
  background-color: $hive-white;
  width: auto;
  height: auto;
  padding: 16px 24px;
}

.titleContainer {
  margin: 0 0 20px 0;
  max-height: 42px;
  min-height: 42px;
}

.title {
  @extend .h_6;
}

.dateAndTime {
  padding: 0px 0px 8px 0;
  @extend .h_7;
  color: rgba($hive-gray, 1);
}

.authorContainer {
  display: flex;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @extend .h_7;
}

.authorName {
  color: $hive-primary-blue;
  text-wrap: wrap;
}

.feedBackContainer {
  position: absolute;
  top: 5px;
  right: 5px;
}

.feedBackContainer:dir(rtl) {
  left: 5px;
  right: auto;
}

.imgWrapper {
  height: 120px;
}

.img {
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-position: top center;
}

.inUserDrawer {
  height: auto;
  width: 100%;
  padding: 0;
  margin-bottom: 15px;

  .container {
    display: grid;
    grid-template-columns: 3fr 5fr;
  }

  .imgWrapper {
    height: auto;
  }
}
