
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  padding: 20px 20%;
  overflow-y: auto;
}

.title {
  @extend .h_1;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.authorName {
  font-weight: 600;
  font-size: 19px;
}
.organisationName {
  font-weight: 400;
  font-size: 19px;
  margin-left: 5px;
}

.date {
  color: $hive-gray;
  font-weight: 400;
  font-size: 16px;
  padding-top: 10px;
}

.timeReadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.readingTime {
  @extend .h_7;
  color: $hive-gray;
  margin-left: 6px;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: $hive-primary-blue;
  border-radius: 12px;
  margin-top: 10px;
  padding: 30px 50px;
  color: rgba($hive-white, 1);
}

.summaryTitle {
  font-weight: 700;
  font-size: 20px;
}

.summaryContent {
  padding-top: 20px;
  font-weight: 400;
  font-size: 16px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.contentFirst {
  margin-top: 20px;
}

.contentTitle {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 20px;
}

.content {
  font-size: 16px;
  font-weight: 400;
  img {
    max-width: 100%;
    height: auto;
  }
}

.imageContainer {
  padding-top: 40px;
}

.image {
  width: 100%;
}

.backButton {
  margin-top: 70px;
  margin-left: 20px;
  margin-right: 20%;
}

.menuItemWrapper {
  flex-grow: 1;
  transition: ease-in 100ms;
  cursor: pointer;
  &:hover {
    background: $hive-sidemenu-item-hover;
    color: $hive-white;
  }
}

.menuItem {
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
}

.moreMenu {
  :global(.ant-btn-circle) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-left: 10px;
  }
}

.addToCollectionButton {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  background-color: transparent;
  color: $hive-primary-blue;
  transition: 100ms ease;
  border-radius: 50%;
  border: 1px solid $hive-primary-blue;
  box-shadow: 0 2px 0 rgba($hive-black, 2%);
  cursor: pointer;
  &:hover {
    background-color: $hive-medium-gray;
    color: $hive-primary-blue;
    border-color: $hive-primary-blue;
    cursor: pointer;
  }
}

.popoverContainer {
  :global(.ant-popover-inner) {
    border-radius: 8px;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 30px 0;
}

.flex {
  display: flex;
}
.articleActionsOuter {
  display: flex;
  align-items: center;
}

.checkImage {
  margin-right: 10px;
  path {
    fill: $hive-primary-blue;
  }
}

.disabled {
  pointer-events: none;
}

.domain {
  font-size: 14px;
  color: $hive-primary-blue;
  padding-bottom: 5px;
  cursor: pointer;
}
.publishTime {
  padding-bottom: 5px;
  color: $hive-gray;
  font-size: 14px;
}

.publishedAndOrganisationContainer {
  display: flex;
  flex-direction: column;
}

.link {
  padding-bottom: 5px;
}
