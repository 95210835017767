
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.container {
  width: 50%;
  right: auto;
  bottom: auto;
  border-radius: 12px;
  padding: 40px;
  background: $hive-category-background-color;
  outline: none;
  max-height: 90%;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.close:dir(rtl) {
  left: 24px;
  right: auto;
}

.title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 22px;
}

.searchContainer {
  position: relative;
  padding: 10px 0;
}

.searchIcon {
  top: 10px;
}

.searchInput {
  border-radius: 100px;
  padding: 10px 40px;
  height: initial;
  background: $hive-white;
  width: 100%;
  border: 2px solid $hive-light-gray;
  &:focus {
    border: 2px solid $hive-primary-blue;
  }
  &:focus-visible {
    outline: none;
  }
}

.label {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
}

.subTitle {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
}

.memberItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.memberList {
  margin-top: 30px;
  overflow-y: auto;
}

.memberName {
  font-size: 20px;
  font-weight: 300;
}

.memberPosition {
  font-size: 13px;
  color: $hive-gray;
  font-weight: 300;
}

.errorName {
  font-size: 13px;
  color: $hive-pure-red;
  font-weight: 300;
}

.memberImage {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: top center;
}

.checked {
  margin-left: auto;
  width: 40px;
  height: 40px;
}

.checked:dir(rtl) {
  margin-right: auto;
  margin-left: 10px;
}

.unChecked {
  @extend .checked;
  border-radius: 100%;
  border-width: 1px;
  border: 2px solid $hive-white-silver;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexContainer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.cancelButton {
  background: none;
  border: none;
  padding: 0;
  color: $hive-primary-blue;
  cursor: pointer;
  font-size: 16px;
}

.createButton {
  background-color: $hive-primary-blue;
  color: $hive-white;
  border: none;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 100px;
  cursor: pointer;
}

.inlineInputWrapper {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.inlineInputButton {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
}
