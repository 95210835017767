
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.container::-webkit-scrollbar {
  display: none;
}

.navbar {
  height: 100%;
  display: flex;
  width: 40%;
}

.dashboard {
  width: 100%;
  background-color: $hive-light-gray;
  overflow: hidden;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabTitle {
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 40px;
  color: $hive-grey-black;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  margin-top: 50px;
  margin-top: 50px;
  margin-right: 20px;
}

.contentWrapper {
  flex-grow: 1;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
