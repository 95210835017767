
          @import "src/scss/styles.scss";
        
.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.collectionInfo {
  display: flex;
}

.peopleList {
  max-height: 280px;
  overflow-y: auto;
  margin: 20px 0 0 0;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.personItem {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.avatar {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.removeCollaborator {
  color: $hive-pure-red;
  font-size: 14px;
  cursor: pointer;
}

.title {
  @extend .h_7;
  margin: 0 0 4px 0;
}

.subTitle {
  @extend .h_8;
}

.dotMargin {
  margin: 0 2px;
}
