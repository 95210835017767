
          @import "src/scss/styles.scss";
        
.container {
  background-color: #242d39;
  padding: 30px 80px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.links {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.socialLinksContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.link {
  height: 28px;
}

.awardLogo {
  height: 40px;
}
