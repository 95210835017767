
          @import "src/scss/styles.scss";
        
@import "../../scss/styles";

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: $hive-light-gray;
  overflow-y: auto;
  padding: 20px;
}

.pageTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.pageSubTitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.newCommunityForm {
  width: 100%;
  max-width: 583px;
  padding: 10px;
}

.selectWrapper {
  position: relative;
}

.communityTypeWrapper {
  padding: 15px 0;
}

.communityTypeHeading {
  @extend .h_6;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  color: $hive-black;
}

.communityTypeHeading:dir(rtl) {
  text-align: right;
}

.inlineInputButton {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
}

.member {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.userDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  .image {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: top center;
  }
}
.role {
  margin-left: 40px;
  color: $hive-gray;
  font-size: 13px;
}

.name {
  padding-left: 10px;
  font-weight: 350;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  width: 100%;
  padding: 5px 0;
}

.cancelLink {
  display: flex;
  margin: auto;
  color: $hive-primary-blue;
  cursor: pointer;
}

.descriptionContainer {
  border: 1px solid $hive-black;
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 4px 10px;
  border-radius: 100px;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.membersLoaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.communityTypeIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  img {
    width: 14px;
    height: 14px;
  }
}
