
          @import "src/scss/styles.scss";
        
@import './../../../../scss/styles.scss';

.hostingTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hostingTabTitle {
  @extend .tabTitle;
}

.createEventContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10%;
}

.tabContainer {
  display: flex;
  flex-direction: column;
}

.hostingEventsContainer {
  @extend .eventsContainer;
}
