
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  height: 100%;
  overflow: auto;
}

.bannerSection {
  height: 170px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.backButton {
  margin-left: 20px;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.infoContainer {
  max-width: 70%;
  width: 100%;
  color: $hive-dark-grey;
}

.discussionsContainer {
  width: 25%;
  padding-right: 10px;
}

.goalsTitle {
  font-weight: bold;
  margin-top: 50px;
}

.titleContainer {
  display: flex;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.communityImage {
  background-position: center;
  background-size: cover;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 100%;
}

.communityIconContainer {
  background-color: $hive-disabled-background;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-left: 10px;
  height: 32px;
  width: 32px;
}

.discussionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recentDiscussions {
  font-weight: bold;
  font-size: 16px;
}

.hashtag {
  font-size: 18px;
  font-weight: 500;
  font-family: cursive;
  cursor: pointer;
}

.interest {
  color: $hive-gray;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.separator {
  padding: 0 20px;
  margin-top: 28px;
  margin-bottom: 40px;

  hr {
    border-top: 1px solid $hive-neutral;
  }
}

.collectionsSpaceContainer {
  margin: 0 20px;
  padding-bottom: 20px;

  .communitySpaceTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .communitySpaceTitle {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $hive-grey-black;
    }

    * {
      margin: 0;
      padding: 0;
    }
  }

  .collectionsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    gap: 16px;
  }
}
