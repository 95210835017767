
          @import "src/scss/styles.scss";
        
.tag {
  border-radius: 100px;
  width: auto;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  gap: 2px;
}
