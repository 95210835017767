
          @import "src/scss/styles.scss";
        
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: max-content auto;
  justify-content: center;
  gap: 15px;
  width: 100%;
}

.valueContainer {
  max-height: 100px;
  overflow: auto;
  scrollbar-width: thin;
}

.value {
  overflow-wrap: anywhere;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.userLabel {
  color: $hive-primary-blue;
  cursor: pointer;
}
