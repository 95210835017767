
          @import "src/scss/styles.scss";
        
.label {
  display: flex;
  flex-direction: column;
  @extend .h_6;
  color: $hive-black;
}

.subText {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: $hive-gray;
}
