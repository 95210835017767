
          @import "src/scss/styles.scss";
        
@import "../../../scss/_palette";

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

.statusHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.statusHeaderDivider {
  margin: 12px 0 !important;
  background-color: $hive-disabled-background !important;
}

.filters {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .searchWrapper {
    padding: 0;
  }

  .search {
    padding-left: 38px;
    margin: 0;
  }

  .searchRtl {
    padding-right: 38px;
    margin: 0;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .select {
    min-width: 250px;
  }

  .buttonWrapper {
    padding: 0;
  }
}

.allUsersButton {
  &:global(.ant-btn) {
    color: $hive-primary-blue;
    background-color: rgba($hive-selected-pill-blue, 0.3);
    border: none;
    font-weight: 700;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.usersButton {
  padding: 0;
  margin-top: 5px;
}

.userRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;

  sup {
    width: 10px !important;
    height: 10px !important;
  }
}

.tag {
  border-radius: 30px !important;
  padding: 10px;
  margin: 0 !important;
  width: fit-content;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-size: cover;
  background-position: top center;
  cursor: pointer;
}

.organization {
  color: $hive-grey-name;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.nameValue {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.031rem;
  color: $hive-black;
}

.emailValue {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.031rem;
  color: $hive-grey-name;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
