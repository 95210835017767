
          @import "src/scss/styles.scss";
        
@import "../../scss/styles.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  @extend .h_2;
}

.subtitle {
  @extend .h_5;
  margin-top: 20px;
  width: 80%;
}
