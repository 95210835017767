@mixin breakpoint($point) {
  @if $point == xs {
    @media screen and (max-width: 480px) {
      @content;
    }
  } @else if $point == sm {
    @media screen and (max-width: 768px) {
      @content;
    }
  } @else if $point == md {
    @media screen and (max-width: 900px) {
      @content;
    }
  } @else if $point == lg {
    @media screen and (max-width: 1200px) {
      @content;
    }
  } @else if $point == xl {
    @media screen and (max-width: 1440px) {
      @content;
    }
  }
}
