
          @import "src/scss/styles.scss";
        
.datePicker {
  background-color: $hive-white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 4px 10px;
  font-size: 12px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  background-color: $hive-disabled-background;
  color: $hive-disabled-color;
  border-color: $hive-disabled-background;
}

.dateIcon {
  width: 15px;
  height: 15px;
}

.dateValue {
  display: inline-block;
  padding-left: 2px;
  white-space: nowrap;
  font-size: 14px;
}

.picker {
  z-index: 5;

  > div {
    display: none;
  }
  > span > div > div {
    border: none;
    border-radius: 10px;
  }
}
