
          @import "src/scss/styles.scss";
        
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid $hive-header-color;
  padding: 15px 40px 15px 15px;
}

.subContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.imageAvatar {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: $hive-dark-blue;
}
