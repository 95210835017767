
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20%;
}

.container {
  background-color: $hive-light-gray;
}

.text {
  color: $hive-gray;
  font-size: 16px;
  font-weight: 400;
  width: 400px;
  text-align: center;
}

.message {
  color: $hive-grey-black;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 20px;
  margin-top: 30px;
}
