
          @import "src/scss/styles.scss";
        
@import "./../../../scss/styles.scss";

.row {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
  height: 50px;
  border-bottom: 1px solid $hive-darker-gray;
  cursor: pointer;
}

.selectedRow {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
  height: 50px;
  border-bottom: 1px solid $hive-darker-gray;
  background-color: $hive-selected-community-background;
}

.communitiesWrapper {
  height: 450px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.communityImage {
  height: 30px;
  min-width: 30px;
  width: 30px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.buttonsSection {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.noCommunities {
  font-weight: 700;
  text-align: center;
  margin-top: 200px;
}

.closeImage {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.closeImage:dir(rtl) {
  left: 30px;
  right: auto;
}

.communityName {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
