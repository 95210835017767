
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.radioWrapper {
  margin: 5px 0;
  display: flex;
  align-items: center;
  position: relative;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radioLabel {
      font-size: 14px;
      font-weight: 600;
      .labelWrapper {
        display: inline-flex;
        flex-direction: column;
        // Fix for longer label texts. 38px = width of radio + margin
        max-width: calc(100% - 38px);
        .subLabel {
          font-size: 14px;
          font-weight: 400;
          color: $hive-grey-name;
        }
      }
      &:before {
        content: "";
        background: $hive-dirty-white;
        border-radius: 100%;
        border: 1px solid $hive-disabled-color;
        display: inline-flex;
        width: 24px;
        height: 24px;
        position: relative;
        top: 0.5em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 200ms ease;
      }
    }
    &:checked {
      + .radioLabel {
        &:before {
          background-color: $hive-dirty-white;
          box-shadow: inset 0 0 0 7px $hive-primary-blue;
          border-color: $hive-primary-blue;
        }
      }
      + .radioImageLabel {
        .radioImage {
          background-color: $hive-dirty-white;
          opacity: 0.6;
        }
      }
      &:disabled {
        + .radioLabel {
          &:before {
            box-shadow: inset 0 0 0 7px $hive-disabled-color;
            border-color: $hive-disabled-color;
            background: transparent;
            cursor: not-allowed;
          }
        }
      }
    }
    + .radioImageLabel {
      width: 100%;
      .radioImage {
        width: 100%;
        transition: 200ms ease;
        outline: 2px solid transparent;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &:focus {
      + .radioLabel {
        &:before {
          outline: none;
          border-color: $hive-primary-blue;
        }
      }
    }
    &:disabled {
      + .radioLabel {
        &:before {
          box-shadow: inset 0 0 0 4px $hive-dirty-white;
          border-color: $hive-disabled-color;
          background: transparent;
          cursor: not-allowed;
        }
      }
    }
    + .radioLabel {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.radioWrapper:dir(rtl) {
  input[type="radio"] {
    + .radioLabel {
      &:before {
        margin-right: 0;
        margin-left: 1em;
      }
    }
  }
}

.removeIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.checkIcon {
  @extend .removeIcon;
}
