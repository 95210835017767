
          @import "src/scss/styles.scss";
        
.container {
  :global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    gap: 5px;

    &.noBorder {
      border: none;
    }

    &.textSm {
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.5px;
    }

    svg {
      shape-rendering: auto !important;
    }

    &:global(.ant-btn-circle) {
      height: 32px;
      padding: 5px;
      border-radius: 32px;
    }
  }
}

.hiveButton {
  background-color: transparent;
  border-color: $hive-primary-blue;
  color: $hive-primary-blue;
  transition: 100ms ease;

  &:hover {
    background-color: $hive-medium-gray;
    color: $hive-primary-blue;
    border-color: $hive-primary-blue;
    cursor: pointer;
  }

  &:disabled {
    background-color: $hive-disabled-background;
    color: $hive-disabled-color;
    border-color: $hive-disabled-background;

    &:hover {
      background-color: $hive-disabled-background;
      cursor: not-allowed;
    }

    svg path {
      fill: $hive-disabled-color;
    }
  }
}

.hiveButtonPrimary {
  background-color: $hive-primary-blue;
  border-color: transparent;
  color: $hive-light-gray;

  &:hover {
    background-color: $hive-primary-blue;
  }

  &:focus {
    background-color: $hive-primary-blue;
  }

  &:disabled {
    background-color: $hive-disabled-background;
    color: $hive-disabled-color;
    border-color: $hive-disabled-background;
  }
}

.hiveButtonDanger {
  background-color: $hive-danger-red;
  border-color: $hive-danger-red;
  color: $hive-white;

  &:hover {
    color: $hive-white;
    border-color: $hive-danger-red-hover;
    background: $hive-danger-red-hover;
  }
}

.hiveButtonTernary {
  color: $hive-primary-blue;
  border-color: $hive-primary-blue;
  background-color: transparent;
}
