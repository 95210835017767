
          @import "src/scss/styles.scss";
        
@import "../../../scss/styles";
.pageWrapper {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 60px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  text-align: left;
  color: $hive-grey-black;
}

.title:dir(rtl) {
  text-align: right;
}

.reporter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 100%;
  background-size: cover;
  background-position: top center;
}

.subject {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
