
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";
.pageWrapper {
  flex-grow: 1;
  overflow-y: auto;
  padding: 35px 20px 20px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -2.5px;
  text-align: left;
  color: $hive-grey-black;
}

.title:dir(rtl) {
  text-align: right;
}

.pendingInvitationsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
