
          @import "src/scss/styles.scss";
        
.requestApprovalCard {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 16px;
}

.info:dir(rtl) {
  text-align: right;
}

.user {
  display: flex;
}

.avatarWrapper {
  padding-right: 10px;
  cursor: pointer;
}

.avatarWrapper:dir(rtl) {
  padding-right: 0px;
  padding-left: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-size: cover;
  background-position: top center;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: $hive-primary-blue;
  cursor: pointer;
}

.notOnboardedName {
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.text {
  max-width: 370px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.5px;
  word-break: break-word;
  color: $hive-gray;
}

.btn {
  display: inline-block;
  padding: 0;
  max-width: 100%;

  :global(.ant-btn) {
    display: flex;
    max-width: 100%;
    height: auto;
    border: none;
    padding: 0;
    line-height: 21px;

    span {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  :global(.ant-btn):hover {
    background-color: transparent;
  }
}

.linkText {
  font-weight: 700;
  color: $hive-primary-blue;
  cursor: pointer;
}

.time {
  font-size: 11px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
}

.acceptBtn {
  background-color: $hive-black;
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  .button {
    padding: 0;

    img {
      padding-right: 0px;
      padding-left: 5px;
    }
  }
}

.acceptButton:dir(rtl) {
  div {
    transform: translateY(-2px);
  }
}

.icon {
  display: inline-block;
  padding-left: 8px;
}
.iconBeforeTitle {
  display: inline-block;
  padding-right: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notificationPageWrapper {
  .text {
    max-width: 100% !important;
  }
  .time {
    flex-shrink: 0;
  }
}
