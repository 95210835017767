
          @import "src/scss/styles.scss";
        
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba($hive-white, 0.6);
  text-align: center;
  margin: 10px 10px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba($hive-black, 0.05);
  border-radius: 12px;
  transition: 150ms ease;
  height: 250px;
  padding: 16px 24px;
  &:hover {
    box-shadow: 0px 4px 12px rgba($hive-black, 0.2);
  }

  > div > span {
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.menuWrapper {
  flex-shrink: 0;
  margin-left: auto;
}

.menuWrapper:dir(rtl) {
  margin-left: 0;
  margin-right: auto;
}

.menuMockDiv {
  height: 32px;
  width: 32px;
}

.icon {
  height: 45px;
  display: block;
  margin: 40px auto 15px auto;
}

.folderNameInput {
  width: 100%;
  height: 30px !important;
  border-radius: 4px !important;
  border-color: $hive-disabled-background !important;
  background-color: $hive-category-background-color !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.clickable {
  cursor: pointer;
}
