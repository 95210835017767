
          @import "src/scss/styles.scss";
        
@import "./../../scss/styles.scss";

.removeIcon {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}
  
.closeButtonWrapper {
    :global(.ant-btn-circle) {
      border: none;
    }
}

.ant-btn-circle {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
}

.modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
}

.previewDocumentModal {
    width: 80%;
    height: 100%;
}

.objectClass {
   width: 100%;
   height: 90%;
   object-fit: contain;
}
