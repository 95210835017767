
          @import "src/scss/styles.scss";
        
.collectionsContainer {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
  overflow-y: auto;
}

.marginWrapper {
  margin: 0 10px;
  width: calc(100% - 20px);
}