
          @import "src/scss/styles.scss";
        
.container {
  padding: 3px 15px;
  text-align: center;

  &.danger {
    background-color: $hive-danger-red;
    border-radius: 20px;
    color: $hive-white;
  }
}
